import {
  Avatar,
  Checkbox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  NurtureIcon,
  Tooltip,
  truncateString,
} from '@gonurture/design-system';
import { useState } from 'react';
import PropTypes from 'prop-types';

const UserAssignmentDropdown = ({ userAssignment }) => {
  const [absentChecked, setAbsentChecked] = useState(false);
  const [notSubmittedChecked, setNotSubmittedChecked] = useState(false);

  const handleAbsentClicked = () => {
    setAbsentChecked(!absentChecked);
  };

  const handleNotSubmittedClicked = () => {
    setNotSubmittedChecked(!notSubmittedChecked);
  };

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <NurtureIcon icon='grey-ellipsis-vertical' />
        </DropdownMenuTrigger>

        <DropdownMenuContent className='min-w-[250px] sm:min-w-[300px] sm:max-w-[400px] overflow-x-scroll'>
          <DropdownMenuGroup>
            <DropdownMenuLabel className='font-normal'>
              <div className='flex space-x-2 sm:px-2 py-1'>
                <Avatar src={userAssignment?.user?.avatar_url} size='lg' />

                <div>
                  <div className='font-semibold text-sm text-[#344054]'>
                    {userAssignment?.user?.display_name}
                  </div>
                  <Tooltip
                    trigger={
                      <div className='text-sm text-[#475467]'>
                        {truncateString(userAssignment?.user?.email, 25)}
                      </div>
                    }
                  >
                    {userAssignment?.user?.email}
                  </Tooltip>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuLabel className='font-normal'>
              <div className='flex space-x-2 sm:px-2 py-4'>
                <NurtureIcon icon='grey-user-pen' />
                <div className='text-sm text-[#344054] font-medium'>
                  Students feedback profile
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>
              <div className='flex space-x-3 sm:px-2 py-1 items-start'>
                <Checkbox
                  checked={absentChecked}
                  className='data-[state=checked]:bg-[#7F56D9] data-[state=checked]:border-[#7F56D9] mt-1 rounded'
                  name='absentCheckbox'
                  onCheckedChange={handleAbsentClicked}
                />
                <div>
                  <div className='text-[#344054] font-medium text-sm'>
                    Mark student as absent.
                  </div>
                  <div className='text-[#475467] text-sm font-normal'>
                    Assessment will still be available to student but wont
                    affect their overall stats unless they complete it.{' '}
                  </div>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>
              <div className='flex space-x-3 sm:px-2 py-1 items-start'>
                <Checkbox
                  checked={notSubmittedChecked}
                  className='data-[state=checked]:bg-[#7F56D9] data-[state=checked]:border-[#7F56D9] mt-1 rounded'
                  name='absentCheckbox'
                  onCheckedChange={handleNotSubmittedClicked}
                />
                <div>
                  <div className='text-[#344054] font-medium text-sm'>
                    Mark student as 'Not Submitted'
                  </div>
                  <div className='text-[#475467] text-sm font-normal'>
                    This will cancel any gain in engagement score for the
                    student if they submitted on Nurture but their submission
                    was not genuine.
                  </div>
                </div>
              </div>
            </DropdownMenuLabel>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

UserAssignmentDropdown.defaultProps = {
  userAssignment: {},
};

UserAssignmentDropdown.propTypes = {
  userAssignment: PropTypes.object,
};

export default UserAssignmentDropdown;
