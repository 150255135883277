import { createRequest } from 'services/ApiService';

export const DeleteObjective = async (channelId, objectiveId) => {
  try {
    return await createRequest({
      url: `objectives/${objectiveId}`,
      method: 'delete',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
