import { createRequest } from 'services/ApiService';

export const addAttachment = async (
  channelId,
  file,
  onUploadProgress = null,
) => {
  try {
    return createRequest({
      url: 'attachments',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      payload: file,
      queryParams: { group_id: channelId },
      ...(onUploadProgress && { onUploadProgress }),
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAttachment = async (channelId, attachmentId, payload) => {
  try {
    return createRequest({
      url: `attachments/${attachmentId}`,
      method: 'patch',
      payload,
      queryParams: { group_id: channelId },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeAttachment = async (channelId, attachmentId) => {
  try {
    return createRequest({
      url: `attachments/${attachmentId}`,
      method: 'delete',
      queryParams: { group_id: channelId },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
