import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import { useDispatch } from 'react-redux';
import {
  AssessmentStats,
  AssignmentPageHeader,
  Badge,
  Button,
  H4,
  Tabs,
  useToast,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { setAppError } from 'store/reducers/app-error-reducer';
import { updateUserAssignment } from 'store/reducers/user-assignments-reducer';
import { userAssignmentShow } from 'apis';
import BackButton from 'components/back-button/BackButton';
import AssignmentDetails from './AssignmentDetails';
import FeedbackDetails from './FeedbackDetails/FeedbackDetails';

const UserAssignmentShow = () => {
  const [userAssignmentLoading, setUserAssignmentLoading] = useState(false);
  const { channelId, userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const dispatch = useDispatch();
  const { toast } = useToast();
  const errorHandler = useErrorHandler();

  const fetchUserAssignment = async () => {
    try {
      setUserAssignmentLoading(true);
      const assignment = await userAssignmentShow(channelId, userAssignmentId);
      dispatch(updateUserAssignment(assignment));
      setUserAssignmentLoading(false);
    } catch (e) {
      setUserAssignmentLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignment',
          variant: 'error',
        });
        dispatch(
          setAppError({ message: 'Error occurred when fetching assignment' }),
        );
      });
    }
  };

  useEffect(() => {
    fetchUserAssignment();
  }, []);

  return (
    <>
      {userAssignmentLoading && 'Loading...'}
      {!userAssignmentLoading && (
        <div className='mt-5'>
          <div className='mb-6 flex justify-between items-center flex-wrap'>
            <div>
              <BackButton />
            </div>

            <Button
              className='hover:bg-[#7F56D9] hover:opacity-90'
              color='#7F56D9'
              textColor='#FFFFFF'
            >
              Submit Assessment
            </Button>
          </div>

          <div className='mb-6'>
            <AssignmentPageHeader
              title={currentUserAssignment?.work.title}
              dueAt={currentUserAssignment?.work.due_date}
              studentSubmittedAt={currentUserAssignment?.submitted_at}
              feedbackPublishedAt={
                currentUserAssignment?.work.feedback_published_at
              }
              reflectionCompletedAt={currentUserAssignment?.reflected_at}
            />
          </div>

          <div className='mb-6'>
            <AssessmentStats
              engagementScore='50'
              graded={true}
              feedback_published={
                !!currentUserAssignment?.submissions?.[0]?.feedback?.points
              }
              confidence={
                currentUserAssignment?.submissions?.[0]?.confidence_level_emoji
              }
              grade={currentUserAssignment?.submissions?.[0]?.feedback?.points}
              reflected={!!currentUserAssignment?.reflected_at}
              reflection={
                currentUserAssignment?.submissions?.[0]?.feedback?.reflection
                  ?.emoji
              }
            />
          </div>

          <div className='mb-6'>
            <Tabs
              defaultValue='assessment'
              tabListClass='bg-[#F9F5FF] h-14 p-1.5'
              tabTriggerClass='bg-transparent data-[state=active]:bg-[#E9D7FE] h-full'
              tabs={[
                {
                  name: (
                    <div className='flex'>
                      <H4 className='mr-3'>Assessment</H4>
                      <Badge
                        className='bg-[#F4F3FF] hover:bg-[#F4F3FF] text-[#5925DC]'
                        text='New'
                      />
                    </div>
                  ),
                  content: (
                    <AssignmentDetails
                      assignment={currentUserAssignment?.work}
                    />
                  ),
                  value: 'assessment',
                },
                {
                  name: <div>Feedback</div>,
                  content: <FeedbackDetails />,
                  value: 'feedback',
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserAssignmentShow;
