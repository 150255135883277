import { Card, H2 } from '@gonurture/design-system';
import { useAppError } from 'store/selectors';
import PropTypes from 'prop-types';

const AppError = ({ children }) => {
  const appError = useAppError();

  return (
    <>
      {appError && (
        <Card>
          <H2>{appError.message}</H2>
        </Card>
      )}

      {!appError && children}
    </>
  );
};

AppError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppError;
