import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import { Button } from '@gonurture/design-system';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addFeedbackReflection } from 'store/reducers/user-assignments-reducer';
import PropTypes from 'prop-types';

const GradeDisplay = ({ createdReflection }) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const dispatch = useDispatch();

  const close = () => {
    dispatch(
      addFeedbackReflection({
        userAssignmentId,
        feedbackId: feedback.id,
        reflection: createdReflection,
      }),
    );
  };

  const grade = () => {
    if (currentAssignment?.grade_display === 'grade_display_points') {
      return `${feedback?.points} / ${currentAssignment?.max_points}`;
    } else if (
      currentAssignment?.grade_display === 'grade_display_percentage'
    ) {
      return `${feedback?.points}%`;
    }
  };

  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = 'hidden';

    // Enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div>
      <div className='fixed inset-0 backdrop-blur-sm'></div>
      <div className='fixed inset-0 flex items-center justify-center z-50'>
        <div
          className='border-2 border-black rounded-lg px-4 md:px-8 py-4 pt-52 text-center mb-8 bg-white z-51'
          style={{ boxShadow: '1px 5px' }}
        >
          <div className='text-3xl text-[#101828] mb-5'>
            Well done{' '}
            {currentUserAssignment?.user?.display_name?.split(' ')?.[0]}!
          </div>
          <p className='text-[#344054] mb-6 text-lg'>
            You got <strong>{grade()}</strong> on this assessment and your
            engagement score was <strong>10/10</strong>
          </p>
          <Button
            className='bg-[#7F56D9] text-white hover:bg-[#7F56D9] w-full mb-4'
            onClick={close}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

GradeDisplay.propTypes = {
  createdReflection: PropTypes.object.isRequired,
};

export default GradeDisplay;
