import FeedbackPublishImage from '@gonurture/design-system/dist/assets/publish-feedback.svg';
import { Button, useToast } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import * as api from 'apis/';
import { useClassroom } from 'store/selectors';
import { addAssignment } from 'store/reducers/assignments-reducer';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const FeedbackPublishCard = ({ assignment }) => {
  const [feedbackPublishLoading, setFeedbackPublishLoading] = useState(false);

  const { channelId } = useClassroom();
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  const FEEDBACK_PUBLISHABLE_STATUS = ['feedback_complete'];
  const submissionsWithFeedback =
    assignment?.user_works?.filter((user_work) =>
      FEEDBACK_PUBLISHABLE_STATUS.includes(user_work.feedback_status),
    ) || [];
  const submissionsWithFeedbackCount = submissionsWithFeedback?.length || 0;

  const buttonEnabledClassName =
    'bg-[#7F56D9] border-[#7F56D9] text-[#FFFFFF] hover:bg-[#7F56D9] hover:border-[#7F56D9] hover:text-[#FFFFFF]';

  const buttonDisabled = submissionsWithFeedbackCount === 0;

  const publishFeedback = async () => {
    try {
      setFeedbackPublishLoading(true);
      const result = await api.publishFeedback(channelId, assignment.id);
      console.log(result);
      dispatch(addAssignment(result));
      setFeedbackPublishLoading(false);
      toast({
        description: 'Feedback published successfully!',
        title: 'Success!',
      });
    } catch (e) {
      setFeedbackPublishLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when creating feedback',
          variant: 'destructive',
        });
      });
    }
  };

  return (
    <div className='md:flex rounded-lg items-center justify-between py-3 md:py-6 px-3 md:px-6 bg-[#F9F5FF] border border-[#D6BBFB]'>
      <div className='mb-6 md:mb-0 sm:flex items-center'>
        <div className='mr-4 mb-6 sm:mb-0 flex'>
          <img src={FeedbackPublishImage} alt={'Illustration'} />
        </div>
        <div>
          <div className='text-[#101828] font-semibold text-xl mb-4 text-center sm:text-left'>
            Publish Feedback
          </div>
          <div className='text-[#344054] text-base'>
            {submissionsWithFeedbackCount} student feedback updates to publish
          </div>
        </div>
      </div>

      <div>
        <Button
          className={`px-6 text-lg w-full shadow-sm ${buttonDisabled ? buttonEnabledClassName : buttonEnabledClassName}`}
          disabled={buttonDisabled || feedbackPublishLoading}
          loading={feedbackPublishLoading}
          loaderText='Publishing...'
          withLoader={true}
          onClick={publishFeedback}
        >
          Publish Feedback
        </Button>
      </div>
    </div>
  );
};

FeedbackPublishCard.defaultProps = {
  assignment: {},
};

FeedbackPublishCard.propTypes = {
  assignment: PropTypes.object,
};

export default FeedbackPublishCard;
