import { createRequest } from 'services/ApiService';

export const configure = async (payload) => {
  try {
    return await createRequest({
      url: `/teams/configure`,
      method: 'post',
      payload,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};
