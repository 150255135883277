import { createSlice } from '@reduxjs/toolkit';
import { dateToEpoch } from '@gonurture/design-system';

export const userAssignmentsSlice = createSlice({
  name: 'UserAssignments',
  initialState: {},
  reducers: {
    addUserAssignments: (state, action) => {
      const userAssignments = action.payload;
      userAssignments.forEach((userAssignment) => {
        state[userAssignment.id] = userAssignment;
      });
      return state;
    },
    updateUserAssignment: (state, action) => {
      const { id } = action.payload;
      state[id] = { ...state[id], ...action.payload };
      return state;
    },
    clearUserAssignments: (state) => {
      for (const id in state) {
        delete state[id];
      }
      return state;
    },
    addUserAssignmentSubmission: (state, action) => {
      const { id } = action.payload;
      const currentUserAssignment = state[id];
      if (currentUserAssignment.submissions.length === 0) {
        currentUserAssignment.submissions = [action.payload.submission];
      } else {
        currentUserAssignment.submissions = [
          ...currentUserAssignment.submissions,
          action.payload.submission,
        ];
      }

      currentUserAssignment.submitted_at = dateToEpoch(new Date());

      state[id] = currentUserAssignment;

      return state;
    },
    updateUserAssignmentSubmission: (state, action) => {
      const { id } = action.payload;
      const currentUserAssignment = state[id];

      let submissions = currentUserAssignment.submissions.map((submission) => {
        if (submission.id === action.payload.submission.id) {
          return {
            ...submission,
            ...action.payload.submission,
          };
        }
        return submission;
      });

      currentUserAssignment.submitted_at = dateToEpoch(new Date());

      state[id] = { ...currentUserAssignment, submissions };

      return state;
    },
    addSubmissionFeedback: (state, action) => {
      const { userAssignmentId, submissionId, feedback } = action.payload;
      const currentUserAssignment = state[userAssignmentId];
      const submission = currentUserAssignment.submissions.find(
        (submission) => submission.id === submissionId,
      );
      submission.feedback = { ...(submission.feedback || {}), ...feedback };

      currentUserAssignment.reflected_at = dateToEpoch(new Date());
      currentUserAssignment.submissions = currentUserAssignment.submissions.map(
        (sub) => (sub.id === submissionId ? submission : sub),
      );

      state[userAssignmentId] = currentUserAssignment;

      return state;
    },
    addFeedbackReflection: (state, action) => {
      const { userAssignmentId, feedbackId, reflection } = action.payload;
      const currentUserAssignment = state[userAssignmentId];

      const submissions = currentUserAssignment.submissions.map(
        (submission) => {
          if (submission.feedback?.id === feedbackId) {
            return {
              ...submission,
              feedback: {
                ...submission.feedback,
                reflection,
              },
            };
          }
          return submission;
        },
      );

      currentUserAssignment.reflected_at = dateToEpoch(new Date());
      currentUserAssignment.submissions = submissions;

      state[userAssignmentId] = currentUserAssignment;
      return state;
    },
  },
});

export const {
  addUserAssignments,
  updateUserAssignment,
  clearUserAssignments,
  addUserAssignmentSubmission,
  updateUserAssignmentSubmission,
  addSubmissionFeedback,
  addFeedbackReflection,
} = userAssignmentsSlice.actions;

export default userAssignmentsSlice.reducer;
