import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, action) => {
      state = action.payload;
      return state;
    },
    removeUser: (state) => {
      state = {};
      return state;
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
