import applicationConfig from '../../applicationConfig';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import StorageService from 'services/StorageService';
import { Button } from '@gonurture/design-system';

const AdminConsent = () => {
  const [consentError, setConsentError] = useState('');
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get('tenant_id');
  const channelId = searchParams.get('channel_id');
  const clientId = applicationConfig.microsoftTeams.clientId;
  const redirectUri = `${applicationConfig.appBaseUrl}/adminconsent/redirect`;
  const scopes = 'https://graph.microsoft.com/.default';

  const handleConsent = () => {
    const url = `https://login.microsoftonline.com/${tenantId}/v2.0/adminconsent?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=12345`;
    const consentWindow = window.open(url, 'consentTab');

    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== applicationConfig.appBaseUrl) {
          return; // Security check
        }
        if (event.data === 'consent-granted') {
          consentWindow.close();
          StorageService.set('consentGranted', 'True');
          window.location.href = `/teams/${tenantId}/channels/${channelId}`;
        } else if (event.data === 'consent-denied') {
          setConsentError(
            'An error occurred while granting consent. Please try again or reach out to your administrator for assistance.',
          );
        }
      },
      false,
    );
  };

  return (
    <>
      {consentError && (
        <>
          <div>{consentError}</div>
          <Button onClick={handleConsent}>Try Again</Button>
        </>
      )}

      {!consentError && (
        <Button onClick={handleConsent}>Grant Admin Consent</Button>
      )}
    </>
  );
};

export default AdminConsent;
