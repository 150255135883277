import { Select, TextArea } from '@gonurture/design-system';
import { useClassroom } from '../../store/selectors';
import { useErrorHandler } from 'hooks/';
import { updateAttachment } from 'apis/';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const AttachmentMetadata = ({
  attachmentId,
  defaultPurpose,
  defaultDescription,
  onUpdate,
}) => {
  const [purpose, setPurpose] = useState(defaultPurpose);
  const [description, setDescription] = useState(defaultDescription);

  const { channelId } = useClassroom();
  const options = [
    {
      label: 'Document Resource',
      value: 'document_resource',
    },
    {
      label: 'Rubrics',
      value: 'rubrics',
    },
    {
      label: 'Exam Descriptors',
      value: 'exam_descriptors',
    },
    {
      label: 'Textbook extracts',
      value: 'textbook_extracts',
    },
    {
      label: 'Sample Exam Papers',
      value: 'sample_exam_papers',
    },
    {
      label: 'Schemes of Work',
      value: 'schemes_of_work',
    },
    {
      label: 'Termly Plans',
      value: 'termly_plans',
    },
    {
      label: 'Fortnightly Plans',
      value: 'fortnightly_plans',
    },
    {
      label: 'Studied Texts',
      value: 'studied_texts',
    },
    {
      label: 'Document Questions',
      value: 'document_questions',
    },
    {
      label: 'Reading Comprehensions',
      value: 'reading_comprehensions',
    },
    {
      label: 'Diagrams',
      value: 'diagrams',
    },
    {
      label: 'Subject Specifications',
      value: 'subject_specifications',
    },
    {
      label: 'Inspectorate Report',
      value: 'inspectorate_report',
    },
    {
      label: 'Curriculum Guides',
      value: 'curriculum_guides',
    },
    {
      label: 'Lesson Plans',
      value: 'lesson_plans',
    },
    {
      label: 'Assessment Criteria',
      value: 'assessment_criteria',
    },
    {
      label: 'Exemplar Answers',
      value: 'exemplar_answers',
    },
    {
      label: 'Study Guides',
      value: 'study_guides',
    },
    {
      label: 'Instructional Materials',
      value: 'instructional_materials',
    },
    {
      label: 'Professional Development Resources',
      value: 'professional_development_resources',
    },
    {
      label: 'Student exemplars',
      value: 'student_exemplars',
    },
  ];

  const errorHandler = useErrorHandler();

  const handleAttachmentChange = async (value, key) => {
    const setterMethod = {
      purpose: setPurpose,
      description: setDescription,
    };

    setterMethod[key](value);
    const newPurpose = key === 'purpose' ? value : purpose;
    const newDescription = key === 'description' ? value : description;

    try {
      const payload = {
        metadata: { purpose: newPurpose, description: newDescription },
      };

      const updatedAttachments = await updateAttachment(
        channelId,
        attachmentId,
        payload,
      );
      onUpdate(updatedAttachments);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setPurpose(defaultPurpose);
  }, [defaultPurpose]);

  useEffect(() => {
    setDescription(defaultDescription);
  }, [defaultDescription]);

  return (
    <div className='mt-8'>
      <div className='mb-4'>
        <Select
          options={options}
          label='What type of attachment is this? (Optional)'
          value={purpose}
          onChange={(value) => handleAttachmentChange(value, 'purpose')}
        />
      </div>

      <div className='mb-4'>
        <TextArea
          value={description}
          onBlur={(e) => handleAttachmentChange(e.target.value, 'description')}
          onChange={(e) => setDescription(e.target.value)}
          label='Describe this attachment for the Nurture Assistant (Optional)'
        />
        <p className='text-sm text-[#475467]'>
          This helps the Nurture Assistant create better feedback later.
          Students will not see this information.
        </p>
      </div>
    </div>
  );
};

AttachmentMetadata.defaultProps = {
  defaultPurpose: '',
  defaultDescription: '',
  onUpdate: () => {},
};

AttachmentMetadata.propTypes = {
  attachmentId: PropTypes.string.isRequired,
  defaultPurpose: PropTypes.string,
  defaultDescription: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default AttachmentMetadata;
