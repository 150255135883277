import { createRequest } from 'services/ApiService';

export const createReflection = async (channelId, data) => {
  try {
    return await createRequest({
      url: `reflections`,
      method: 'post',
      payload: data,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateReflection = async (channelId, reflectionId, data) => {
  try {
    return await createRequest({
      url: `reflections/${reflectionId}`,
      method: 'patch',
      payload: data,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
