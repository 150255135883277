import { AiAssistantChat } from '@gonurture/design-system';

const FeedbackAiChat = () => {
  const messages = [
    {
      contents: [{ type: 'text', value: 'Hello! How are you today?' }],
      sender: {
        name: 'Emer Cunningham',
        avatar: 'https://example.com/human-avatar.jpg',
      },
      requiresUserAction: false,
    },
    {
      contents: [
        {
          type: 'text',
          value: 'I am the Nurture Assistant, how can I assist you?',
        },
      ],
      requiresUserAction: false,
    },
    {
      contents: [{ type: 'text', value: 'Can you help me with my project?' }],
      sender: {
        name: 'Emer Cunningham',
        avatar: 'https://example.com/human-avatar.jpg',
      },
      requiresUserAction: false,
    },
    {
      contents: [
        {
          type: 'text',
          value:
            "Great, I have filled in the text content of the assessment for you. If you need to include any attachments or links, don't forget to add them before publishing to students. Chat with me if you need help with making the assessment more defined for your students.",
        },
        { type: 'link', value: 'https://example.com/resources' },
      ],
      requiresUserAction: true,
      userActionType: 'ctaButtons',
    },
    {
      contents: [{ type: 'text', value: 'Can you help me with my project?' }],
      sender: {
        name: 'Emer Cunningham',
      },
      requiresUserAction: false,
    },
    {
      contents: [
        {
          type: 'text',
          value:
            "Great, I have filled in the text content of the assessment for you. If you need to include any attachments or links, don't forget to add them before publishing to students",
        },
      ],
      requiresUserAction: true,
      userActionType: 'yesOrNoButtons',
    },
  ];

  return (
    <div
      className='
        prose-table:table-auto prose-table:border-collapse
        prose-table:border prose-table:overflow-x-auto prose-table:w-full
        prose-table:my-3 prose-th:border prose-th:p-2 prose-td:text-sm prose-th:text-sm
        prose-td:border prose-td:p-2 prose-tr:even:bg-gray-50
        prose-p:mb-2 prose-p:text-sm prose-headings:font-semibold
        prose-ul:list-disc prose-ul:list-inside prose-li:text-sm prose-li:mb-1 prose-ul:mb-2
        prose-ol:list-decimal prose-ol:list-inside prose-ol:mb-2
    '
    >
      <AiAssistantChat messages={messages} onSubmitPrompt={() => {}} />
    </div>
  );
};

export default FeedbackAiChat;
