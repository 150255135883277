import { useFieldArray } from 'react-hook-form';
import { useToast, SortableLearningOutcomes } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { DeleteObjective } from 'apis/';
import { useClassroom } from 'store/selectors';

const LearningObjectivesFields = ({ control }) => {
  const { toast } = useToast();
  const { channelId } = useClassroom();

  const { fields, append, remove, swap, update } = useFieldArray({
    control,
    name: 'objectives',
  });

  const onAppend = () => {
    append({ name: '' });
  };

  const onRemove = async (index) => {
    if (fields.length === 1) {
      toast({
        variant: 'destructive',
        description: 'You must have at least one objective',
      });
      return;
    }

    if (fields[index]._id) {
      await DeleteObjective(channelId, fields[index]._id);
    }

    remove(index);
  };

  const onChange = (index, newValue) => {
    update(index, { ...fields[index], name: newValue });
  };

  const onDragEnd = (sourceIndex, destinationIndex) => {
    swap(sourceIndex, destinationIndex);
  };

  return (
    <>
      <SortableLearningOutcomes
        objectives={fields}
        onAdd={onAppend}
        onDelete={onRemove}
        onChange={onChange}
        onDragEnd={onDragEnd}
      />
    </>
  );
};

LearningObjectivesFields.propTypes = {
  control: PropTypes.object.isRequired,
};

export default LearningObjectivesFields;
