import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentAssignment } from '../../../../../store/selectors';
import { FeedbackFormSchema as FeedbackFormSchemaGenerator } from 'form-schemas/';
import {
  Input,
  NurtureIcon,
  Switch,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignmentShow, createFeedback, updateFeedback } from 'apis/';
import { addSubmissionFeedback } from 'store/reducers/user-assignments-reducer';
import FileUploader from 'components/attachments/FileUploader';
import Attachments from 'components/attachments/Attachments';
import RubricsGradingTable from '../rubrics-grading-table/RubricsGradingTable';
import { addAssignment } from '../../../../../store/reducers/assignments-reducer';
import PropTypes from 'prop-types';

const FeedbackForm = ({
  submission,
  defaultFeedback,
  assignment,
  userAssignmentId,
}) => {
  const [feedbackCreateError, setFeedbackCreateError] = useState(false);
  const [feedbackCreateLoading, setFeedbackCreateLoading] = useState(false);
  const [feedback, setFeedback] = useState(defaultFeedback);
  const [feedbackAttachments, setFeedbackAttachments] = useState(
    defaultFeedback?.attachments || [],
  );
  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const [objectiveIds, setObjectiveIds] = useState([]);
  const [timerID, setTimerID] = useState();
  const FeedbackFormSchema = FeedbackFormSchemaGenerator(
    currentAssignment?.max_points,
  );
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FeedbackFormSchema),
  });

  const handleCheckedChange = (checked, objectiveId) => {
    clearTimeout(timerID);
    let finalObjectives;

    if (checked) {
      setObjectiveIds((prev) => {
        const result = [...prev, objectiveId];
        setValue('objective_ids', result);
        finalObjectives = result;
        return result;
      });
    } else {
      setObjectiveIds((prev) => {
        const result = prev.filter((id) => id !== objectiveId);
        setValue('objective_ids', result);
        finalObjectives = result;
        return result;
      });
    }

    const timerId = setTimeout(() => {
      onSubmit({ objective_ids: finalObjectives });
    }, 5000); // wait 5 seconds before submitting in case they still want to check on uncheck
    setTimerID(timerId);
  };

  const handleRubricsGradeChange = (result) => {
    clearTimeout(timerID);

    const timerId = setTimeout(() => {
      onSubmit(result);
    }, 5000);
    setTimerID(timerId);
  };

  const handlePointsChanged = (e) => {
    const points = e.target.value;
    setValue('points', points);
    onSubmit({ points: points });
  };

  const handleCommentChanged = (e) => {
    const comment = e.target.value;
    setValue('comment', comment);
    onSubmit({ comment: comment });
  };

  const isChecked = (objectiveId) => objectiveIds.includes(objectiveId);

  const newFeedback = async () => {
    try {
      const payload = {
        submission_id: submission?.id,
        user_work_id: userAssignmentId,
      };
      setFeedbackCreateLoading(true);
      setFeedbackCreateError(false);
      const result = await createFeedback(channelId, payload);
      setFeedbackAttachments(() => result?.attachments);
      setFeedback(result);
      setFeedbackCreateLoading(false);
    } catch (e) {
      setFeedbackCreateLoading(false);
      setFeedbackCreateError(true);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when creating feedback',
          variant: 'destructive',
        });
      });
    }
  };

  const handleFeedbackUpload = async (attachment) => {
    setFeedbackAttachments((prev) => [...(prev || []), attachment]);
    toast({
      title: 'Success!',
      description: 'Attachment uploaded successfully',
      variant: 'success',
    });
    if (submission) {
      dispatch(
        addSubmissionFeedback({
          userAssignmentId: userAssignmentId,
          submissionId: submission?.id,
          feedback: {
            ...feedback,
            attachments: [...(feedback?.attachments || []), attachment],
          },
        }),
      );
    }
  };

  const handleFeedbackAttachmentsDelete = (attachment) => {
    setFeedbackAttachments((prev) =>
      prev.filter((a) => a.id !== attachment.id),
    );

    toast({
      title: 'Success!',
      description: 'Attachment deleted successfully',
      variant: 'success',
    });

    if (submission) {
      dispatch(
        addSubmissionFeedback({
          userAssignmentId: userAssignmentId,
          submissionId: submission?.id,
          feedback: {
            ...feedback,
            attachments: feedback.attachments.filter(
              (a) => a.id !== attachment.id,
            ),
          },
        }),
      );
    }
  };
  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        submission_id: submission?.id,
        user_work_id: userAssignmentId,
      };
      // setFeedbackUpdateLoading(true);
      const result = await updateFeedback(channelId, feedback.id, payload);
      if (submission) {
        dispatch(
          addSubmissionFeedback({
            userAssignmentId: userAssignmentId,
            submissionId: submission?.id,
            feedback: result,
          }),
        );
      }
      toast({ description: 'Feedback saved successfully' });
      refetchAssessment(); // dont wait for this, let it run in the background
    } catch (e) {
      // setFeedbackUpdateLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when creating feedback',
          variant: 'destructive',
        });
      });
    }
  };

  const refetchAssessment = async () => {
    const assignment = await assignmentShow(channelId, assignmentId);
    dispatch(addAssignment(assignment));
  };

  useEffect(() => {
    if (!defaultFeedback && userAssignmentId) newFeedback();
  }, [userAssignmentId]);

  useEffect(() => {
    setValue('points', feedback?.points);
    setValue('comment', feedback?.comment);
    setValue('objective_ids', feedback?.objectives || []);
    setObjectiveIds(() => feedback?.objectives || []);
  }, [feedback]);

  return (
    <>
      {feedbackCreateError && (
        <div>
          <p>Error occurred when creating feedback</p>
        </div>
      )}

      {!feedbackCreateError && feedbackCreateLoading && (
        <div>
          <p>Loading...</p>
        </div>
      )}

      {!feedbackCreateError && !feedbackCreateLoading && (
        <div>
          <div className='font-merriweather text-2xl mb-4 text-[#101828]'>
            Personal Feedback
          </div>

          {assignment?.grade_display !== 'grade_display_rubrics' && (
            <div className='mb-6'>
              <div className='font-semibold text-[#101828] text-lg mb-1'>
                Learning Outcomes student needs feedback on
              </div>
              <div className='text-[#475467] text-sm'>
                You can check multiple learning outcomes or none.
              </div>
              <div className='mt-4'>
                {assignment?.objectives?.map((objective) => {
                  return (
                    <div key={objective.id} className='mb-3'>
                      <Switch
                        className='data-[state=checked]:bg-[#7F56D9]'
                        label={objective.name}
                        checked={isChecked(objective.id)}
                        onCheckedChange={(e) =>
                          handleCheckedChange(e, objective.id)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {(assignment?.grade_display === 'grade_display_points' ||
            assignment?.grade_display === 'grade_display_percentage') && (
            <div className='mb-6'>
              <Input
                error={errors?.points?.message}
                type='number'
                label='Points'
                {...register('points')}
                max={assignment?.max_points}
                min={0}
                onBlur={handlePointsChanged}
              />
            </div>
          )}

          {assignment?.grade_display === 'grade_display_rubrics' && (
            <div className='mb-6'>
              <RubricsGradingTable
                assignment={assignment}
                feedback={feedback}
                onGraded={handleRubricsGradeChange}
              />
            </div>
          )}

          <div className='mb-6'>
            <TextArea
              error={errors?.comment?.message}
              label='Personal Feedback Comment'
              placeholder='Enter the students personal feedback here...'
              rows={6}
              {...register('comment')}
              onBlur={handleCommentChanged}
            />
          </div>

          <div className='mb-6'>
            <FileUploader
              attachableParams={{ feedback_id: feedback?.id }}
              buttonView
              buttonViewClassName='bg-[#FFFFFF] hover:bg-[#FFFFFF] border hover:border border-[#D0D5DD] hover:border-[#D0D5DD] font-semibold text-sm text-[#344054] hover:text-[#344054] rounded-lg'
              buttonViewContent={
                <div className='flex items-center]'>
                  <NurtureIcon className='mr-2' icon='black-image-plus' /> Add
                  Attachments
                </div>
              }
              onUpload={handleFeedbackUpload}
            />
            <Attachments
              className='mt-3'
              onDelete={handleFeedbackAttachmentsDelete}
              attachments={feedbackAttachments}
            />
          </div>
        </div>
      )}
    </>
  );
};

FeedbackForm.defaultProps = {
  assignment: undefined,
  submission: undefined,
  defaultFeedback: undefined,
  userAssignmentId: undefined,
};

FeedbackForm.propTypes = {
  assignment: PropTypes.object,
  submission: PropTypes.object,
  defaultFeedback: PropTypes.object,
  userAssignmentId: PropTypes.string,
};

export default FeedbackForm;
