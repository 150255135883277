import { createRequest } from 'services/ApiService';

export const getMembers = async (channelId) => {
  try {
    return await createRequest({
      url: `teams/classrooms/${channelId}/members`,
      method: 'get',
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
