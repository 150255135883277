import { H2 } from '@gonurture/design-system';
import { useParams } from 'react-router-dom';
import AssignmentForm from 'components/assignment-form/AssignmentForm';
import BackButton from 'components/back-button/BackButton';
import { useClassroom } from 'store/selectors';

const AssignmentEdit = () => {
  const { assignmentId } = useParams();
  const { channelId, tenantId } = useClassroom();

  return (
    <div>
      <div className='my-5'>
        <BackButton
          to={`/teams/${tenantId}/channels/${channelId}/assignment/${assignmentId}`}
        />
      </div>

      <H2>Assignment Edit</H2>
      <AssignmentForm
        context='AssignmentEdit'
        defaultAssignmentId={assignmentId}
      />
    </div>
  );
};

export default AssignmentEdit;
