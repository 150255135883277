import AssignmentForm from 'components/assignment-form/AssignmentForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroom } from 'store/selectors';
import AssignmentTypeHeader from 'components/assignment-type-header/AssignmentTypeHeader';
import HeaderWithBackButton from 'components/header-with-back-button/HeaderWithBackButton';
import AssessmentAiChat from 'components/ai-chat/AssessmentAiChat';
import { ScrollArea } from '@gonurture/design-system';
import { useState } from 'react';

const AssignmentNew = () => {
  const [assignmentId, setAssignmentId] = useState(null);
  const { assignmentType } = useParams();
  const { channelId, tenantId } = useClassroom();
  const navigate = useNavigate();

  const handleBack = () =>
    navigate(`/teams/${tenantId}/channels/${channelId}/assignment/type`);

  return (
    <div className='flex flex-col lg:flex-row'>
      {/* Left Column - Main Content */}
      <ScrollArea className='w-full order-2 lg:order-1 lg:w-8/12 p-6 lg:border-r lg:h-screen lg:overflow-y-scroll px-2'>
        <HeaderWithBackButton
          header={<AssignmentTypeHeader assignmentType={assignmentType} />}
          onBack={handleBack}
        />

        <div className=''>
          <AssignmentForm onCreateNewAssignment={setAssignmentId} />
        </div>
      </ScrollArea>

      {/* Right Column - AI Chat */}
      <ScrollArea className='w-full h-screen overflow-y-scroll order-1 lg:order-2 lg:w-4/12 lg:pl-0 px-2'>
        <AssessmentAiChat assignmentId={assignmentId} />
      </ScrollArea>
    </div>
  );
};

export default AssignmentNew;
