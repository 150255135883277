import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const HeaderWithBackButton = ({ header, onBack }) => {
  return (
    <div className='sm:flex sm:items-center sm:space-x-6 mb-10'>
      <Button outline className='sm:mr-2 mb-3 sm:mb-0' onClick={onBack}>
        <NurtureIcon icon='black-arrow-left' />
      </Button>
      <div className='sm:text-2xl text-lg'>{header}</div>
    </div>
  );
};

HeaderWithBackButton.defaultProps = {
  onBack: () => {},
};

HeaderWithBackButton.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onBack: PropTypes.func,
};

export default HeaderWithBackButton;
