import {
  NurtureIcon,
  ScrollArea,
  User,
  Sheet,
  useToast,
  SubmissionStatusBadge,
  StudentSubmissionCard,
  StudentReflectionCard,
  Separator,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import {
  useCurrentAssignment,
  useCurrentUserAssignment,
} from 'store/selectors';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { userAssignmentShow } from 'apis/';
import { useDispatch } from 'react-redux';
import { addUserAssignments } from 'store/reducers/user-assignments-reducer';
import { useErrorHandler } from 'hooks/';
import FeedbackAiChat from './feedback-ai-chat/FeedbackAiChat';
import UserAssignmentPagination from './UserAssignmentPagination';
import UserAssignmentDropdown from './UserAssignmentDropdown';
import FeedbackForm from './feedback-form/FeedbackForm';

const UserAssignmentModal = ({ opened, defaultUserAssignmentId, onClose }) => {
  const [userAssignmentId, setUserAssignmentId] = useState(null);
  const [prevButtonLoading, setPrevButtonLoading] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const userAssignment = useCurrentUserAssignment(userAssignmentId);
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();

  const availableUserAssignments = currentAssignment?.user_works;
  const currentIndex = availableUserAssignments?.findIndex(
    (userAssignment) => userAssignment.id === userAssignmentId,
  );

  const locate = async (direction) => {
    const movement = { prev: -1, next: 1 };
    const buttonLoadingFunction = {
      prev: setPrevButtonLoading,
      next: setNextButtonLoading,
    };

    try {
      // No need to move to next or prev if only one submission
      if (availableUserAssignments?.length === 1) return;

      if (currentIndex === -1) return;

      let newIndex = currentIndex + movement[direction];
      if (newIndex === -1)
        newIndex = availableUserAssignments?.length - 1 || currentIndex;
      if (newIndex >= availableUserAssignments?.length) newIndex = 0;
      const newUserAssignment = availableUserAssignments[newIndex];

      buttonLoadingFunction[direction](true);

      const result = await userAssignmentShow(channelId, newUserAssignment.id);
      dispatch(addUserAssignments([result]));
      setUserAssignmentId(newUserAssignment.id);
      buttonLoadingFunction[direction](false);
    } catch (e) {
      buttonLoadingFunction[direction](false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching student work',
          variant: 'destructive',
        });
      });
    }
  };

  const prev = () => locate('prev');

  const next = () => locate('next');

  useEffect(() => {
    setUserAssignmentId(defaultUserAssignmentId);
  }, [defaultUserAssignmentId]);

  return (
    <Sheet
      contentClassName='w-full xl:w-3/4'
      opened={opened}
      showDefaultClose={false}
    >
      <div className='flex flex-col md:flex-row'>
        {/* Left Column - Main Content */}
        <ScrollArea className='w-full order-2 md:order-1 md:w-8/12 p-6 md:border-r md:h-screen md:overflow-y-scroll px-2 sm:px-3'>
          <div className='flex justify-between mb-8'>
            {/*CLose Button*/}
            <div
              className='flex cursor-pointer items-center border border-[#D0D5DD] hover:bg-gray-100 py-1 px-4 font-semibold text-sm text-[#344054] rounded-lg transition-all duration-100'
              onClick={onClose}
            >
              Close{' '}
              <NurtureIcon icon='x-close' className='ml-1 w-[16px] h-[16px]' />
            </div>

            {/*Top Pagination*/}
            <UserAssignmentPagination
              total={availableUserAssignments?.length}
              currentIndex={currentIndex}
              nextLoading={nextButtonLoading}
              prevLoading={prevButtonLoading}
              onNext={next}
              onPrev={prev}
            />
          </div>

          <div className='flex justify-between mb-8'>
            <User user={userAssignment?.user || {}} />

            <div className='flex space-x-4 items-center'>
              <SubmissionStatusBadge submission={userAssignment} />

              <UserAssignmentDropdown userAssignment={userAssignment} />
            </div>
          </div>

          {/*Student Submission*/}
          <div className='mb-8'>
            <StudentSubmissionCard
              submission={userAssignment?.submissions[0]}
            />
          </div>

          <div className='mb-8'>
            <FeedbackForm
              submission={userAssignment?.submissions[0]}
              assignment={currentAssignment}
              userAssignmentId={userAssignment?.id}
            />
          </div>

          <div className='mb-8'>
            <StudentReflectionCard
              reflection={userAssignment?.submissions[0]?.feedback?.reflection}
            />
          </div>

          <Separator className='mb-8' />

          <div className='flex items-center justify-between'>
            <User user={userAssignment?.user || {}} />

            {/*Bottom Pagination*/}
            <UserAssignmentPagination
              total={availableUserAssignments?.length}
              currentIndex={currentIndex}
              nextLoading={nextButtonLoading}
              prevLoading={prevButtonLoading}
              onNext={next}
              onPrev={prev}
            />
          </div>
        </ScrollArea>

        {/* Right Column - AI Chat */}
        <ScrollArea className='w-full h-screen overflow-y-scroll order-1 md:order-2 md:w-4/12 md:pl-0 px-2'>
          <FeedbackAiChat />
        </ScrollArea>
      </div>
    </Sheet>
  );
};

UserAssignmentModal.defaultProps = {
  opened: false,
};

UserAssignmentModal.propTypes = {
  opened: PropTypes.bool,
  defaultUserAssignmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserAssignmentModal;
