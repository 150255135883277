import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { addMessage, runThread, updateAssignment } from 'apis/';
import { useClassroom } from 'store/selectors';
import { setAiAssignment } from 'store/reducers/ai-assignment-reducer';
import { useDispatch } from 'react-redux';
import { Button } from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { mdTableToRubricsFormat } from '../../../lib/md-table-to-json';

const AssessmentAssistantLoading = ({
  assignmentId,
  promptData,
  onCancel,
  onDone,
}) => {
  const [error, setError] = useState(null);

  const { channelId } = useClassroom();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const addMessageToThread = async () => {
    try {
      setError(null); // clear any existing error in case the user is retrying

      const data = { ...promptData, work_id: assignmentId };
      const response = await addMessage(channelId, data);
      const threadId = response.id;

      runThread({
        threadId,
        channelId,
        onMessage: handleStreamEvent,
      });
    } catch (error) {
      setError(error);
    }
  };

  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'assistant.assessment': {
        const data = messageEvent.data;

        if (data?.rubrics) {
          data.rubrics_json = mdTableToRubricsFormat(data.rubrics);
        }

        dispatch(setAiAssignment(data));
        setError(null);
        persistAiAssignment(data);
        break;
      }

      case 'thread.run.completed':
        onDone();
        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  };

  const handleErrorEvent = (event) => {
    console.log('error event', event);
    setError('Error occurred while generating assessment');
  };

  const persistAiAssignment = async (assignment) => {
    try {
      // set the objectives in a format where it can be saved in the backend
      const data = { ...assignment };
      data.objectives_attributes =
        data.learningObjectives?.length > 1
          ? data.learningObjectives.map((item) => ({
              name: item,
            }))
          : undefined;

      await updateAssignment(channelId, assignmentId, {
        ...data,
        status: 'unsaved_draft',
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    addMessageToThread();
  }, []);

  return (
    <div className='flex h-screen w-full items-center justify-center'>
      {error && (
        <div>
          <div className='text-red-500 mb-3'>{error.message}</div>
          <div className='d-flex'>
            <Button
              className='mr-2'
              variant='destructive'
              onClick={addMessageToThread}
            >
              Retry
            </Button>
            <Button onClick={onCancel}>Close</Button>
          </div>
        </div>
      )}

      {!error && <div>Loading...</div>}
    </div>
  );
};

AssessmentAssistantLoading.defaultProps = {
  promptData: null,
  onCancel: () => {},
  onDone: () => {},
};

AssessmentAssistantLoading.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  promptData: PropTypes.object,
  onCancel: PropTypes.func,
  onDone: PropTypes.func,
};

export default AssessmentAssistantLoading;
