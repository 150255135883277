import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const UserAssignmentPagination = ({
  currentIndex,
  total,
  nextLoading,
  prevLoading,
  onNext,
  onPrev,
}) => {
  return (
    <div className='flex space-x-2 items-center'>
      <Button
        withLoader={true}
        loading={prevLoading}
        loaderColor='#344054'
        loaderText='.'
        loaderTextColor='#344054'
        className='border bg-[#FFFFFF] hover:border-[#D0D5DD] border-[#D0D5DD] hover:bg-gray-100 py-1 px-4 rounded-lg transition-all duration-100'
        onClick={onPrev}
      >
        <NurtureIcon icon='black-arrow-left' />
      </Button>

      <div className='text-[#344054] font-semibold'>
        {currentIndex + 1}/{total}
      </div>

      <Button
        withLoader={true}
        loading={nextLoading}
        loaderColor='#344054'
        loaderText='.'
        className='border bg-[#FFFFFF] hover:border-[#D0D5DD] border-[#D0D5DD] hover:bg-gray-100 py-1 px-4 rounded-lg transition-all duration-100'
        onClick={onNext}
      >
        <NurtureIcon icon='black-arrow-right' />
      </Button>
    </div>
  );
};

UserAssignmentPagination.defaultProps = {
  nextLoading: false,
  prevLoading: false,
  onNext: () => {},
  onPrev: () => {},
};

UserAssignmentPagination.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  nextLoading: PropTypes.bool,
  prevLoading: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};

export default UserAssignmentPagination;
