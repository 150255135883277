import { DashboardHeader } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export const NAVS = { dashboard: 'dashboard', tasks: 'tasks' };

const TeacherHomeNav = ({ activeTab, onTabChange }) => {
  const navigate = useNavigate();

  const navigateToAssignmentTypePage = () => {
    navigate('assignment/type');
  };

  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  const primaryTabs = [
    {
      name: 'Dashboard',
      value: NAVS.dashboard,
      icon: 'grey-wand',
      activeIcon: 'colorful-wand',
      onClick: () => handleTabClick(NAVS.dashboard),
    },
    {
      name: 'Tasks',
      value: NAVS.tasks,
      icon: 'grey-check-in-circle-overlap',
      activeIcon: 'colorful-check-in-circle',
      onClick: () => handleTabClick(NAVS.tasks),
    },
  ];

  const secondaryAction = {
    name: 'New Task',
    onClick: navigateToAssignmentTypePage,
  };

  return (
    <DashboardHeader
      primaryTabs={primaryTabs}
      activeTab={activeTab}
      secondaryAction={secondaryAction}
    />
  );
};

TeacherHomeNav.defaultProps = {
  onTabChange: () => {},
};

TeacherHomeNav.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(NAVS)).isRequired,
  onTabChange: PropTypes.func,
};

export default TeacherHomeNav;
