import { Outlet, redirect, useParams } from 'react-router-dom';
import StorageService from 'services/StorageService';
import isJwtExpired from 'lib/is-jwt-expired';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/reducers/user-reducer';
import { setClassroomDetails } from 'store/reducers/classroom-reducer';
import { useClassroom, useUser } from 'store/selectors';

export const AuthenticateUser = ({ params }) => {
  const user = StorageService.getObject('user');
  const token = StorageService.get('token');
  const isTokenExpired = isJwtExpired(token);

  if (isTokenExpired || !user) {
    return redirect(
      `/authenticate?channel_id=${params.channelId}&tenant_id=${params.tenantId}`,
    );
  }

  if (!hasAdminConsent()) {
    return redirect(
      `/adminconsent?channel_id=${params.channelId}&tenant_id=${params.tenantId}`,
    );
  }

  return user;
};

// Stub method to verify if consent has been granted or not
const hasAdminConsent = () => {
  return true;
  // return StorageService.get('consentGranted') === 'True';
};

const Protected = () => {
  const dispatch = useDispatch();
  const { channelId, tenantId } = useParams();
  const { channelId: channelIdFromStore, tenantId: tenantIdFromStore } =
    useClassroom();
  const userFromStore = useUser();
  const user = StorageService.getObject('user');

  if (!userFromStore) dispatch(setUser(user));
  if (!channelIdFromStore || !tenantIdFromStore) {
    dispatch(
      setClassroomDetails({
        channelId: channelId,
        tenantId: tenantId,
      }),
    );
  }

  return (
    <div className='px-5'>
      <Outlet />
    </div>
  );
};

export default Protected;
