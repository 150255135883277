import PropTypes from 'prop-types';
import { LearningOutcomesCard } from '@gonurture/design-system';

const LearningObjectives = ({ learningObjectives }) => {
  const outcomes =
    learningObjectives?.map((objective) => {
      return { body: objective.name };
    }) || [];

  return <LearningOutcomesCard outcomes={outcomes} />;
};

LearningObjectives.propTypes = {
  learningObjectives: PropTypes.array.isRequired,
};

export default LearningObjectives;
