import PropTypes from 'prop-types';
import { Button, NurtureIcon } from '@gonurture/design-system';
import { useNavigate } from 'react-router-dom';
import { useClassroom } from 'store/selectors';

const BackButton = ({ to }) => {
  const navigate = useNavigate();
  const { channelId, tenantId } = useClassroom();

  const handleClick = () =>
    navigate(to || `/teams/${tenantId}/channels/${channelId}`);

  return (
    <Button outline={true} className='flex items-center' onClick={handleClick}>
      <NurtureIcon className='mr-3' icon='black-chevron-left' />
      Back
    </Button>
  );
};

BackButton.defaultProps = {
  to: null,
};

BackButton.propTypes = {
  to: PropTypes.string,
};

export default BackButton;
