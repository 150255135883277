import Attachments from 'components/attachments/Attachments';
import LearningObjectives from 'components/learning-objectives/LearningObjectives';
import { Button, AutoLinker, NurtureIcon } from '@gonurture/design-system';
import SubmissionAnimation from 'assets/images/student_submission.gif';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

const AssignmentDetails = ({ assignment, isTeacher }) => {
  const navigate = useNavigate();
  const { channelId, tenantId } = useParams();

  const proseClasses = `
      prose-p:text-base prose-p:font-normal prose-p:text-[#475467] prose-p:mb-3
      prose-ol:list-decimal prose-ol:list-inside prose-ol:mb-2
      prose-li:text-sm prose-li:mb-1 prose-li:text-[#475467]
  `;

  const nonMarkingSchemeAttachments =
    assignment?.attachments?.filter(
      (attachment) => attachment.metadata?.purpose !== 'marking_scheme',
    ) || [];

  const markingSchemeAttachments =
    assignment?.attachments?.filter(
      (attachment) => attachment.metadata?.purpose === 'marking_scheme',
    ) || [];

  const handleEdit = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/${assignment?.id}/edit`,
    );
  };

  return (
    <div className='mt-10'>
      {/*Teacher Summary Card and Edit Assessment Button*/}
      {isTeacher && (
        <div className='border border-[#D0D5DD] p-3 sm:p-5 rounded-lg sm:flex items-center justify-between mb-8'>
          {/*Left*/}
          <div className='mb-4 sm:mb-0'>
            <div className='mb-2 flex'>
              <NurtureIcon icon='purple-eye' className='mr-2' />
              <div className='font-semibold text-[#101828]'>
                This is a student view of the assessment
              </div>
            </div>
            <div className='text-xs text-[#000000]'>
              This is what the student will see on the assessment.{' '}
            </div>
          </div>

          {/*Right*/}
          <div className='flex items-center space-x-2 cursor-pointer'>
            <div
              className='text-sm text-[#7341C6] font-semibold'
              onClick={handleEdit}
            >
              Edit Assessment
            </div>
            <NurtureIcon icon='purple-pen-edit' />
          </div>
        </div>
      )}

      <div className='flex'>
        <div className='pt-0.5 flex-grow'>
          <div className='md:w-2/3 md:mr-5'>
            <div className='font-semibold text-xl'>The Assignment</div>
            <div className='text-sm font-normal text-[#475467]'>
              The assessment description learning objectives and attachments
              your teacher has set for you.
            </div>
          </div>
          <div className='lg:flex lg:space-x-10 mt-7'>
            <div className='lg:w-1/2'>
              {/*Assignment Description*/}
              <div className={`mb-6 ${proseClasses}`}>
                <AutoLinker content={assignment?.description} />
              </div>

              {/*Learning Objectives*/}
              <div className='mb-6 lg:w-full'>
                <LearningObjectives
                  learningObjectives={assignment?.objectives}
                />
              </div>

              {/*Attachments*/}
              {nonMarkingSchemeAttachments?.length > 0 && (
                <div className='mb-6'>
                  <div className='font-semibold text-xl mb-3'>
                    Teacher Attachments
                  </div>
                  <Attachments
                    canDelete={false}
                    attachments={nonMarkingSchemeAttachments}
                  />
                </div>
              )}

              {/*Grading*/}
              {assignment?.grade_display !== 'grade_display_ungraded' && (
                <div className='md:mb-12'>
                  <div className='font-semibold text-xl mb-3'>Grading</div>
                  <div className='text-base font-normal mb-6 text-[#475467]'>
                    {assignment?.grade_display === 'grade_display_points' && (
                      <>
                        The assessment is graded out of{' '}
                        <span className='font-semibold'>
                          {assignment?.max_points} marks
                        </span>
                      </>
                    )}

                    {assignment?.grade_display ===
                      'grade_display_percentage' && (
                      <>
                        The assessment is graded as a{' '}
                        <span className='font-semibold'>Percentage</span>
                      </>
                    )}

                    {assignment?.grade_display === 'grade_display_rubrics' && (
                      <>
                        The assessment is graded with{' '}
                        <span className='font-semibold'>Rubrics</span>
                      </>
                    )}
                  </div>
                </div>
              )}

              {/*Marking scheme*/}
              {(assignment?.marking_scheme_description ||
                markingSchemeAttachments?.length > 0) && (
                <div className='md:mb-12'>
                  <div className='font-semibold text-xl mb-3'>
                    Marking Scheme
                  </div>
                  {assignment?.marking_scheme_description && (
                    <div className='text-base font-normal mb-6 text-[#475467]'>
                      <AutoLinker
                        content={assignment?.marking_scheme_description}
                      />
                    </div>
                  )}

                  {markingSchemeAttachments?.length > 0 && (
                    <Attachments
                      attachments={markingSchemeAttachments}
                      canDelete={false}
                    />
                  )}
                </div>
              )}
            </div>

            <div className='lg:w-1/2'>
              <div className='border px-4 py-6 rounded-md border-[#D6BBFB]'>
                <div className='flex justify-center'>
                  <img
                    src={SubmissionAnimation}
                    width='300px'
                    height='300px'
                  ></img>
                </div>
                <div className='font-semibold text-xl'>
                  Student Instructions
                </div>
                <div className='text-sm font-normal text-[#475467]'>
                  Complete your work for this assessment outside of Nurture
                  either on paper or on a document and once it is ready, submit
                  your work as an attachment here.
                </div>
                <Button
                  disabled={isTeacher}
                  className='hover:bg-[#7F56D9] hover:opacity-90 w-full mt-10'
                  color='#7F56D9'
                  textColor='#FFFFFF'
                >
                  Submit Assessment ➜
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AssignmentDetails.defaultProps = {
  isTeacher: false,
};

AssignmentDetails.propTypes = {
  assignment: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool,
};

export default AssignmentDetails;
