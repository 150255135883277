import { useNavigate, useParams } from 'react-router-dom';
import HeaderWithBackButton from 'components/header-with-back-button/HeaderWithBackButton';
import { useClassroom, useCurrentAssignment } from 'store/selectors';
import {
  Button,
  Input,
  Label,
  NurtureIcon,
  RubricsDropdown,
  Switch,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { createAssignment } from 'apis/';
import {
  addAssignment,
  addAssignmentAttachment,
  removeAssignmentAttachment,
  updateAssignmentAttachment,
} from 'store/reducers/assignments-reducer';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignmentAssistantFormSchema } from 'form-schemas/';
import FileUploader from 'components/attachments/FileUploader';
import Attachments from 'components/attachments/Attachments';
import AssignmentTypeHeader from 'components/assignment-type-header/AssignmentTypeHeader';
import AssignmentQuestionTypeField from './AssignmentQuestionTypeField';
import AssessmentAssistantLoading from './AssessmentAssistantLoading';

const AssignmentAssistant = () => {
  const [assignmentCreateLoading, setAssignmentCreateLoading] = useState(false);
  const [justUploadedAttachments, setJustUploadedAttachments] = useState([]);
  const [assignmentId, setAssignmentId] = useState(null);
  const [showAssessmentAssistantLoading, setShowAssessmentAssistantLoading] =
    useState(false);
  const [promptData, setPromptData] = useState({});
  const { assignmentType } = useParams();
  const { channelId, tenantId } = useClassroom();
  const navigate = useNavigate();

  const { toast } = useToast();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const formConfig = useForm({
    resolver: yupResolver(assignmentAssistantFormSchema),
    defaultValues: {
      curriculum_boundary: false,
      grade_display: `grade_display_${assignmentType}`,
    },
  });

  const {
    register,
    setValue,
    formState: { errors },
    control,
    handleSubmit,
  } = formConfig;

  const assessmentDisplayOptions = [
    { label: 'Long Questions', value: 'long_questions' },
    { label: 'Short Questions', value: 'short_questions' },
    { label: 'Multiple Choice Questions', value: 'multiple_choice_questions' },
    { label: 'Essay Style', value: 'essay_style' },
    { label: 'Matching Exercises', value: 'matching_exercises' },
    { label: 'True/False Statements', value: 'true_false_statements' },
    {
      label: 'Multiple Choice Statements',
      value: 'multiple_choice_statements',
    },
    { label: '"Fill In" Items', value: 'fill_in_items' },
    { label: 'Portfolios', value: 'portfolios' },
    { label: 'Presentations', value: 'presentations' },
    {
      label: 'Reading Comprehension Questions',
      value: 'reading_comprehension',
    },
    { label: 'Document Questions', value: 'document_questions' },
    { label: 'Journals', value: 'journals' },
    { label: 'Projects', value: 'projects' },
    { label: 'Quizzes', value: 'quizzes' },
    { label: 'Case Studies', value: 'case_studies' },
    { label: 'Diagrams/Concept Maps', value: 'diagrams' },
  ];

  const nonMarkingSchemeAttachments = currentAssignment?.attachments?.filter(
    (attachment) => attachment.metadata?.purpose !== 'marking_scheme',
  );

  const markingSchemeAttachments = currentAssignment?.attachments?.filter(
    (attachment) => attachment.metadata?.purpose === 'marking_scheme',
  );

  const goToAssignmentNewPage = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/new/${assignmentType}`,
    );
  };

  const handleBack = () =>
    navigate(`/teams/${tenantId}/channels/${channelId}/assignment/type`);

  const createNewAssignment = async () => {
    try {
      setAssignmentCreateLoading(true);
      const assignment = await createAssignment(channelId, assignmentType);
      dispatch(addAssignment(assignment));
      setAssignmentId(assignment.id);
      setAssignmentCreateLoading(false);
    } catch (e) {
      errorHandler(e, () => {
        setAssignmentCreateLoading(false);
        toast({
          description: 'Error occurred during assignment creation',
          variant: 'destructive',
        });
        dispatch(
          setAppError({ message: 'Error occurred during assignment creation' }),
        );
      });
    }
  };

  const handleAttachmentUpload = (attachment) => {
    setJustUploadedAttachments((prev) => [...prev, attachment]);
  };

  const handleDeletedJustUploadedAttachment = (attachment) => {
    setJustUploadedAttachments((prev) =>
      prev.filter((a) => a.id !== attachment.id),
    );
  };

  const handleJustUploadedAttachmentUpdate = (attachment) => {
    setJustUploadedAttachments((prev) =>
      prev.map((a) => (a.id === attachment.id ? attachment : a)),
    );
  };

  const handleAttachmentReset = () => {
    justUploadedAttachments.forEach((attachment) => {
      dispatch(addAssignmentAttachment({ id: assignmentId, attachment }));
    });

    setJustUploadedAttachments(() => []);
  };

  const handleAttachmentDelete = (attachment) => {
    dispatch(removeAssignmentAttachment({ id: assignmentId, attachment }));
    toast({
      title: 'Success!',
      description: 'Attachment deleted successfully',
      variant: 'success',
    });
  };

  const handleAttachmentUpdate = (attachment) => {
    dispatch(updateAssignmentAttachment({ id: assignmentId, attachment }));
  };

  const onSubmit = (data) => {
    setPromptData(() => data);
    setShowAssessmentAssistantLoading(true);
  };

  useEffect(() => {
    setValue('assessment_grading_type', assignmentType);
    createNewAssignment();
  }, []);

  return (
    <div>
      {assignmentCreateLoading && <>Loading...</>}

      {!assignmentCreateLoading && (
        <>
          {!showAssessmentAssistantLoading && (
            <div className='py-8'>
              <HeaderWithBackButton
                header={
                  <AssignmentTypeHeader assignmentType={assignmentType} />
                }
                onBack={handleBack}
              />

              {/*Teacher Instruction*/}
              <div className='lg:w-1/2 md:w-3/4 md:mx-auto'>
                <div className='mb-10'>
                  <TextArea
                    id='teacher_instruction'
                    placeholder='Enter Topic...'
                    label='What topic do you want this assessment to be on?'
                    error={errors?.teacher_instruction?.message}
                    {...register('teacher_instruction')}
                  />
                </div>

                {/*Attachments Upload*/}
                <div className='mb-10'>
                  <Label>Create questions from an attachment</Label>
                  <div className='text-sm mt-1 text-[#475467]'>
                    Upload a reading comprehension, photo of a textbook, paste a
                    URL of a webpage, YouTube video or anything you want and the
                    Nurture Assistant will generate questions on it.
                  </div>

                  <div className='mt-3'>
                    <FileUploader
                      attachableParams={{ work_id: currentAssignment?.id }}
                      showChildren={true}
                      onUpload={handleAttachmentUpload}
                      onReset={handleAttachmentReset}
                      onDelete={handleDeletedJustUploadedAttachment}
                      onUpdate={handleJustUploadedAttachmentUpdate}
                    />
                  </div>
                </div>

                <div className='mb-10'>
                  <Attachments
                    showChildren={true}
                    attachments={nonMarkingSchemeAttachments}
                    onDelete={handleAttachmentDelete}
                    onUpdate={handleAttachmentUpdate}
                  />
                </div>

                {/*Marking Scheme*/}
                <div className='mb-10'>
                  <Label>Create Marking scheme from an attachment</Label>
                  <div className='text-sm mt-1 text-[#475467]'>
                    Upload a marking scheme as an attachment here and the
                    Nurture Assistant will grade students work based on this.
                  </div>

                  <div className='mt-3'>
                    <FileUploader
                      buttonView={true}
                      buttonViewClassName='bg-[#FFFFFF] hover:bg-[#FFFFFF] border hover:border border-[#D0D5DD] hover:border-[#D0D5DD] font-semibold text-sm text-[#344054] hover:text-[#344054] rounded-lg'
                      buttonViewContent={
                        <div className='flex items-center'>
                          Upload Attachments{' '}
                          <NurtureIcon className='ml-2' icon='black-plus' />
                        </div>
                      }
                      attachableParams={{
                        work_id: currentAssignment?.id,
                        metadata: { purpose: 'marking_scheme' },
                      }}
                      onUpload={handleAttachmentUpload}
                      onReset={handleAttachmentReset}
                      onDelete={handleDeletedJustUploadedAttachment}
                      onUpdate={handleJustUploadedAttachmentUpdate}
                    />
                  </div>
                </div>

                <div className='mb-10'>
                  <Attachments
                    attachments={markingSchemeAttachments}
                    onDelete={handleAttachmentDelete}
                    onUpdate={handleAttachmentUpdate}
                  />
                </div>

                {/*Assessment Type*/}
                <div className='mb-10'>
                  <Controller
                    name='assessment_description_types'
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          <Label>Assessment Type</Label>
                          <AssignmentQuestionTypeField
                            values={value}
                            options={assessmentDisplayOptions}
                            error={
                              errors?.assessment_description_types?.message
                            }
                            onChange={onChange}
                          />
                        </>
                      );
                    }}
                  />
                  <p className='text-sm text-[#475467]'>
                    Assessment description will contain these types of
                    questions.
                  </p>
                </div>

                {/*Maximum marks*/}
                {assignmentType === 'points' && (
                  <div className='mb-10'>
                    <Input
                      className='w-[200px]'
                      label='Maximum Marks'
                      placeholder='e.g 100'
                      error={errors?.max_points?.message}
                      {...register('max_points')}
                    />
                  </div>
                )}

                {/*Rubrics Column Dropdown*/}
                {assignmentType === 'rubrics' && (
                  <div className='mb-10'>
                    <Controller
                      name='criteria_labels'
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <Label>Criteria Labels</Label>
                            <RubricsDropdown
                              error={errors?.criteria_labels?.message}
                              onSelect={onChange}
                              value={value}
                            />
                          </>
                        );
                      }}
                    />
                  </div>
                )}

                {/*Curriculum Boundary*/}
                <div className='mb-10'>
                  <div className='flex space-x-3 items-start'>
                    <Controller
                      name='curriculum_boundary'
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Switch
                            name='curriculum_boundary'
                            className='data-[state=checked]:bg-[#7F56D9]'
                            checked={value}
                            onCheckedChange={onChange}
                          />
                        );
                      }}
                    />
                    <div className='flex-grow'>
                      <div className='mb-2 text-[#344054] font-medium'>
                        Curriculum Boundary
                      </div>
                      <div className='text-[#475467]'>
                        Nurture Assistant will generate content based on your
                        uploaded curriculum.
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mb-5'>
                  <Button
                    className='bg-[#7F56D9] hover:bg-[#7F56D9] text-white w-full'
                    onClick={handleSubmit(onSubmit, (e) => console.log(e))}
                  >
                    Generate Task
                  </Button>
                </div>

                <div
                  className='text-center text-[#7341C6] font-semibold cursor-pointer'
                  onClick={goToAssignmentNewPage}
                >
                  Skip this step and create this task manually
                </div>
              </div>
            </div>
          )}

          {showAssessmentAssistantLoading && (
            <AssessmentAssistantLoading
              assignmentId={assignmentId}
              promptData={promptData}
              onCancel={() => setShowAssessmentAssistantLoading(false)}
              onDone={goToAssignmentNewPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AssignmentAssistant;
