class StorageService {
    static set(key, value) {
        sessionStorage.setItem(key, value);
    }

    static get(key) {
        return sessionStorage.getItem(key);
    }

    static remove(key) {
        sessionStorage.removeItem(key);
    }

    static clear() {
        sessionStorage.clear();
    }

    static getObject(key) {
        return JSON.parse(this.get(key));
    }

    static setObject(key, value) {
        this.set(key, JSON.stringify(value));
    }
}

export default StorageService;