import { useEffect } from 'react';

const AdminConsentRedirect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const adminConsent = urlParams.get('admin_consent');
    const state = urlParams.get('state');

    if (adminConsent === 'True' && state === '12345') {
      if (window.opener) {
        window.opener.postMessage('consent-granted', '*');
      }
      // Close the popup window
      window.close();
    } else {
      // Handle errors or denied consent
      if (window.opener) {
        window.opener.postMessage('consent-denied', '*');
      }
      window.close();
    }
  }, []);

  return (
    <div>
      <h1>Admin Consent Redirect</h1>
    </div>
  );
};

export default AdminConsentRedirect;
