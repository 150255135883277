import { object, string, boolean, array, number } from 'yup';

const assignmentAssistantFormSchema = object({
  teacher_instruction: string().nullable().required('This field is required'),
  grade_display: string().nullable().required(),
  assessment_description_types: array()
    .nullable()
    .required('This field is required'),
  curriculum_boundary: boolean(),
  max_points: number().when('grade_display', ([grade_display], schema) => {
    if (grade_display === 'grade_display_points') {
      return schema
        .required('Max points is required')
        .transform((value) => (isNaN(value) ? 0 : value))
        .min(1, 'The minimum allowable mark is 1')
        .nullable();
    }
  }),
  criteria_labels: string().when('grade_display', ([grade_display], schema) => {
    if (grade_display === 'grade_display_rubrics') {
      return schema.required('This field is required').nullable();
    }
  }),
});

export default assignmentAssistantFormSchema;
