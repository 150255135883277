import { AiAssistantChat, useToast } from '@gonurture/design-system';
import { useEffect, useState } from 'react';
import StorageService from 'services/StorageService';
import { addMessage, getMessages, runThread, updateAssignment } from 'apis/';
import { useClassroom, useCurrentAssignment } from 'store/selectors';
import { useErrorHandler } from 'hooks/';
import { setAiAssignment } from 'store/reducers/ai-assignment-reducer';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { mdTableToRubricsFormat } from '../../lib/md-table-to-json';

const AssessmentAiChat = ({ assignmentId }) => {
  const [messages, setMessages] = useState([]);
  const [aiThinking, setAiThinking] = useState(false);
  const [error, setError] = useState(''); // eslint-disable-line
  const [streaming, setStreaming] = useState(false);
  const [streamMessage, setStreamMessage] = useState('');

  const user = StorageService.getObject('user');
  const { channelId } = useClassroom();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const dispatch = useDispatch();

  const currentAssignment = useCurrentAssignment(assignmentId);

  const handleSubmitPrompt = async (prompt) => {
    const newUserMessage = {
      contents: [{ type: 'text', value: prompt }],
      sender: { name: user.display_name, avatar: user.avatar_url },
      requiresUserAction: false,
    };

    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setAiThinking(true);
    setStreaming(false);

    try {
      await addMessageToThread({
        content: prompt,
        thread_id: currentAssignment?.thread_id,
      });
    } catch (error) {
      console.error('Failed to get AI response:', error);
      setError('Failed to get AI response');
    } finally {
      setAiThinking(false);
    }
  };

  const addMessageToThread = async (promptData) => {
    try {
      setError(null); // clear any existing error in case the user is retrying

      const data = { ...promptData, work_id: assignmentId };
      const response = await addMessage(channelId, data);
      const threadId = response.id;

      runThread({
        threadId,
        channelId,
        onMessage: handleStreamEvent,
      });
    } catch (error) {
      setError(error);
    }
  };

  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'assistant.assessment': {
        const data = messageEvent.data;

        if (data?.rubrics) {
          data.rubrics_json = mdTableToRubricsFormat(data.rubrics);
        }

        dispatch(setAiAssignment(data));
        setError(null);
        persistAiAssignment(data);
        break;
      }

      case 'thread.message.delta': {
        const content = messageEvent?.data.delta.content[0].text.value;
        setStreaming(true);
        setStreamMessage((prev) => prev + content);
        break;
      }

      case 'thread.message.completed':
        handleMessageCompleted(messageEvent);
        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  };

  const handleErrorEvent = (event) => {
    console.log('error event', event);
    setError('Error occurred while generating assessment');
    setStreaming(false);
    setStreamMessage('');
    setAiThinking(false);
  };

  const handleMessageCompleted = (messageEvent) => {
    setMessages((prev) => {
      return [
        ...prev,
        ...[
          {
            contents: [
              { type: 'text', value: messageEvent.data.content[0].text.value },
            ],
            requiresUserAction: false,
          },
        ],
      ];
    });

    setError('');
    setAiThinking(false);
    setStreamMessage('');
    setStreaming(false);
    // firstTokenSent = false;
    // metricService.track({ event: 'ai_success', properties: { scope: 'assistant_chat' } });
    // trackDuration('assistant_chat');
    // scrollToRecentChat();
  };

  const convertMessageToChatFormat = (message) => {
    const result = {};

    result['contents'] = message.contents;
    result['created_at'] = message.created_at;

    if (message['role'] === 'user') {
      result['sender'] = {
        name: user.display_name,
        avatar: user.avatar_url,
      };
    }

    return result;
  };

  const getInitialMessages = async () => {
    try {
      setAiThinking(true);
      const { messages } = await getMessages(
        channelId,
        currentAssignment?.thread_id,
      );
      console.log(messages);
      setMessages(() => {
        return messages?.map((message) => convertMessageToChatFormat(message));
      });
      setAiThinking(false);
    } catch (e) {
      errorHandler(e, () => {
        setAiThinking(false);
        toast({
          description: 'Error occurred when fetching messages',
          variant: 'destructive',
        });
      });
    }
  };

  const persistAiAssignment = async (assignment) => {
    try {
      // set the objectives in a format where it can be saved in the backend
      const data = { ...assignment };
      data.objectives_attributes =
        data.learningObjectives?.length > 1
          ? data.learningObjectives.map((item) => ({
              name: item,
            }))
          : undefined;

      await updateAssignment(channelId, assignmentId, {
        ...data,
        status: 'unsaved_draft',
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (currentAssignment?.thread_id) {
      getInitialMessages();
    }
  }, [currentAssignment]);

  return (
    <div
      className='
        prose-table:table-auto prose-table:border-collapse
        prose-table:border prose-table:overflow-x-auto prose-table:w-full
        prose-table:my-3 prose-th:border prose-th:p-2 prose-td:text-sm prose-th:text-sm
        prose-td:border prose-td:p-2 prose-tr:even:bg-gray-50
        prose-p:mb-2 prose-p:text-sm prose-headings:font-semibold
        prose-ul:list-disc prose-ul:list-inside prose-li:text-sm prose-li:mb-1 prose-ul:mb-2
        prose-ol:list-decimal prose-ol:list-inside prose-ol:mb-2
    '
    >
      <AiAssistantChat
        messages={messages}
        aiThinking={aiThinking}
        onSubmitPrompt={handleSubmitPrompt}
        streaming={streaming}
        streamText={streamMessage}
      />
    </div>
  );
};

AssessmentAiChat.propTypes = {
  assignmentId: PropTypes.string.isRequired,
};

export default AssessmentAiChat;
