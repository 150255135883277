import StorageService from 'services/StorageService';
import TeacherHome from './TeacherHome/TeacherHome';
import StudentHome from './StudentHome';

const Home = () => {
  const user = StorageService.getObject('user');

  return (
    <div>
      {user.role === 'teacher' && <TeacherHome />}

      {user.role === 'student' && <StudentHome />}
    </div>
  );
};

export default Home;
