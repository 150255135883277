import PropTypes from 'prop-types';
import { Avatar } from '@gonurture/design-system';
import { useEffect, useState } from 'react';

const FeedbackObjectives = ({ assignment }) => {
  const [objectives, setObjectives] = useState([]);
  const [userAssignments, setUserAssignments] = useState([]);

  const getUserAvatar = (userAssignmentId) => {
    const userAssignment = userAssignments?.find(
      (ua) => ua.id === userAssignmentId,
    );
    return userAssignment?.user?.avatar_url;
  };

  useEffect(() => {
    setObjectives(assignment?.objectives || []);
    setUserAssignments(assignment?.user_works || []);
  }, [assignment]);

  return (
    <div className='rounded-lg border border-[#EAECF0] shadow-sm p-1 sm:p-3'>
      {objectives?.map((objective, index) => (
        <div
          key={index}
          className={`py-3 md:py-5 px-1 sm:px-4 md:px-5 ${index < objectives.length - 1 ? 'border-b' : ''}`}
        >
          <div
            className={`flex items-center space-x-1 sm:space-x-2`}
            key={objective}
          >
            <div className='flex items-center justify-center w-8 h-8 bg-[#7F56D9] rounded-full text-white'>
              {index + 1}
            </div>

            <div>{objective.name}</div>
          </div>

          {objective.feedback_users_output.length > 0 && (
            <div className='flex flex-wrap mt-2'>
              <div className='w-8 mr-2 sm:mr-4'></div>

              {objective.feedback_users_output.map((output, index) => (
                <div className='m-[-4px]' key={index}>
                  <Avatar
                    className='border-2 border-[#FFFFFF]'
                    size='md'
                    src={getUserAvatar(output.user_work_id)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

FeedbackObjectives.propTypes = {
  assignment: PropTypes.object.isRequired,
};

export default FeedbackObjectives;
