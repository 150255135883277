import { createSlice } from '@reduxjs/toolkit';
import { mdTableToRubricsFormat } from 'lib/md-table-to-json';

export const aiAssignmentSlice = createSlice({
  name: 'aiAssignment',
  initialState: null,
  reducers: {
    setAiAssignment: (state, action) => {
      if (action.payload.rubrics) {
        action.payload.rubrics = mdTableToRubricsFormat(action.payload.rubrics);
      }

      state = action.payload;
      return state;
    },
    clearAiAssignment: (state) => {
      state = null;
      return state;
    },
  },
});

export const { setAiAssignment, clearAiAssignment } = aiAssignmentSlice.actions;

export default aiAssignmentSlice.reducer;
