import { jwtDecode } from 'jwt-decode';

const isJwtExpired = (token) => {
  if (typeof token !== 'string' || !token) return true;
  const { exp } = jwtDecode(token);

  const currentTime = new Date().getTime() / 1000;

  return currentTime > exp;
};

export default isJwtExpired;
