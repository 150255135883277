import User from 'components/user/User';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import { TextArea } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const ReflectionCommentState = ({
  reflectionCommentValue,
  onChangeReflectionComment,
}) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;

  return (
    <div>
      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      <div className='text-[#344054] mb-5'>
        Do you have any personal comment to add?
      </div>

      <TextArea
        value={reflectionCommentValue}
        onChange={onChangeReflectionComment}
      />
    </div>
  );
};

ReflectionCommentState.defaultProps = {
  reflectionCommentValue: undefined,
  onChangeReflectionComment: () => {},
};

ReflectionCommentState.propTypes = {
  reflectionCommentValue: PropTypes.number,
  onChangeReflectionComment: PropTypes.func,
};

export default ReflectionCommentState;
