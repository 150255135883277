import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../../store/selectors';
import User from '../../../../../components/user/User';
import Attachments from '../../../../../components/attachments/Attachments';

const PersonalFeedbackState = () => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  return (
    <div>
      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      <div className='text-[#344054]'>{feedback?.comment}</div>

      {feedback?.attachments && (
        <div className='mt-5'>
          <div className='mb-5'>
            <User user={currentAssignment?.user} />
          </div>

          <div className='mb-5 text-[#344054]'>
            Here are some more <strong>resources</strong> for you to check out:
          </div>

          <Attachments attachments={feedback?.attachments} />
        </div>
      )}
    </div>
  );
};

export default PersonalFeedbackState;
