import User from '../../../../../components/user/User';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from '../../../../../store/selectors';
import { ReflectionSelector } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const FeedbackReflectionState = ({
  reflectionFeelingValue,
  onChangeReflectionFeeling,
}) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;

  return (
    <div>
      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      <div className='text-[#344054] mb-5'>
        How do you feel about your feedback?
      </div>

      <ReflectionSelector
        isVertical={true}
        value={reflectionFeelingValue}
        onSelect={onChangeReflectionFeeling}
      />
    </div>
  );
};

FeedbackReflectionState.defaultProps = {
  reflectionFeelingValue: undefined,
  onChangeReflectionFeeling: () => {},
};

FeedbackReflectionState.propTypes = {
  reflectionFeelingValue: PropTypes.number,
  onChangeReflectionFeeling: PropTypes.func,
};

export default FeedbackReflectionState;
