import {
  Button,
  Dialog,
  NurtureIcon,
  Select,
  TextArea,
} from '@gonurture/design-system';
import { useState } from 'react';
import PropTypes from 'prop-types';

const FeedbackSettingsModal = ({ modalOpened, onClose }) => {
  const [feedbackType, setFeedbackType] = useState('');

  const FEEDBACK_TYPES = [
    {
      label: 'Strengths, Improvements, Progress',
      value: 'Strengths, Improvements, Progress',
    },
  ];

  return (
    <Dialog opened={modalOpened} showCloseButton={false}>
      <div>
        <div className='flex mb-4'>
          <NurtureIcon
            className='p-3 border shadow-sm rounded-lg'
            icon='gradient-stars'
          />
        </div>

        <div className='mb-4'>
          <div className='text-[#181D27] text-lg font-semibold'>
            Customise the Nurture Assistant
          </div>

          <div className='text-[#535862] text-sm'>
            This will instruct the assistant how to create feedback for all
            students on this assessment. These instructions are not visible to
            students.
          </div>
        </div>

        <div className='mb-8'>
          <div className='mb-3'>
            <Select
              label='Feedback Type'
              options={FEEDBACK_TYPES}
              value={feedbackType}
              onChange={setFeedbackType}
            />
          </div>

          <div>
            <TextArea
              label='Custom Instructions'
              rows={5}
              placeholder='e.g. Students regularly make spelling mistakes on this assessment. Pay close attention to their spelling when grading.'
            />
          </div>
        </div>

        <div className='md:flex space-x-2'>
          <div className='mb-2 md:mb-0 md:w-1/2'>
            <Button
              className='bg-white hover:bg-white border border-[#D0D5DD] hover:border-[#D0D5DD] text-[#344054] w-full'
              onClick={onClose}
            >
              Close
            </Button>
          </div>

          <div className='mb-2 md:mb-0 md:w-1/2'>
            <Button className='bg-[#7F56D9] border hover:bg-[#7F56D9] border-[#7F56D9] hover:border-[#7F56D9] text-[#FFFFFF] w-full'>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

FeedbackSettingsModal.defaultProps = {
  modalOpened: false,
  onClose: () => {},
};

FeedbackSettingsModal.propTypes = {
  modalOpened: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FeedbackSettingsModal;
