import { array, number, object, string } from 'yup';

const FeedbackFormSchema = (maxPoint) => {
  return object({
    objective_ids: array().of(number()).nullable(),
    points: number().max(maxPoint).nullable(),
    comment: string().nullable(),
  });
};

export default FeedbackFormSchema;
