import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import msalInstance from 'lib/msal';
import StorageService from 'services/StorageService';
import { Button, Skeleton } from '@gonurture/design-system';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/reducers/user-reducer';
import { getUserProfile } from 'apis/';

const BrowserAuthenticate = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [searchParams] = useSearchParams();
  const [authError, setAuthError] = useState(false);
  const channelId = searchParams.get('channel_id');
  const tenantId = searchParams.get('tenant_id');

  const dispatch = useDispatch();

  const buttonRef = useRef();

  const signIn = () => {
    setAuthenticated(true);
    setIsAuthenticating(true);
    msalInstance
      .loginPopup({ scopes: ['openid', 'profile', 'User.Read'] })
      .then(async (loginResponse) => {
        setAuthError(false);
        StorageService.set('token', loginResponse.idToken);
        const user = await getUserProfile(channelId);
        dispatch(setUser(user));
        StorageService.setObject('user', user);
        setIsAuthenticating(false);
        window.location.href = `/teams/${tenantId}/channels/${channelId}`;
      })
      .catch((error) => {
        setAuthError(true);
        setIsAuthenticating(false);
        StorageService.remove('token');
        console.error('Error during login:', error);
      });
  };

  useEffect(() => {
    if (!authenticated) {
      setTimeout(() => {
        buttonRef?.current?.click();
      }, 2000);
    }
  }, []);

  return (
    <>
      {!authenticated && (
        <button
          ref={buttonRef}
          onClick={signIn}
          style={{ visibility: 'hidden' }}
        >
          Browser Authentication
        </button>
      )}
      {isAuthenticating ? (
        <div className='flex items-center space-x-4'>
          <Skeleton className='h-12 w-12 rounded-full' />
          <div className='space-y-2'>
            <Skeleton className='h-4 w-[250px]' />
            <Skeleton className='h-4 w-[200px]' />
          </div>
        </div>
      ) : (
        <>
          {authError && (
            <>
              <div>Error occurred during authentication</div>
              <Button onClick={signIn}>Try Again</Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BrowserAuthenticate;
