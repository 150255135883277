import { PublicClientApplication } from '@azure/msal-browser';
import applicationConfig from '../applicationConfig';

const msalConfig = {
  auth: {
    clientId: applicationConfig.microsoftTeams.clientId,
    authority: 'https://login.microsoftonline.com/common',
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
