import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  dateToEpoch,
  Form,
  Input,
  NurtureIcon,
  Separator,
  TextArea,
  useToast,
  DateInput,
  Switch,
  RubricsCreationTable,
  epochToDate,
} from '@gonurture/design-system';

import { assignmentFormSchema } from 'form-schemas';
import LearningObjectivesFields from './LearningObjectivesFields';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  useAiAssignment,
  useClassroom,
  useCurrentAssignment,
} from 'store/selectors';
import { useDispatch } from 'react-redux';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useErrorHandler } from 'hooks/';
import FileUploader from 'components/attachments/FileUploader';
import {
  addAssignment,
  addAssignmentAttachment,
  removeAssignmentAttachment,
  updateAssignment,
  updateAssignmentAttachment,
} from 'store/reducers/assignments-reducer';
import { useNavigate, useParams } from 'react-router-dom';
import Attachments from 'components/attachments/Attachments';
import {
  createAssignment,
  DeleteObjective,
  updateAssignment as updateAssignmentApi,
} from 'apis/';
import StudentsAssignField from './StudentsAssignField';

const AssignmentForm = ({
  context,
  defaultAssignmentId,
  onCreateNewAssignment,
}) => {
  const [justUploadedAttachments, setJustUploadedAttachments] = useState([]);
  const [assignmentCreateLoading, setAssignmentCreateLoading] = useState(false);
  const [assignmentId, setAssignmentId] = useState(defaultAssignmentId);
  const [assignmentUpdateLoading, setAssignmentUpdateLoading] = useState(false);
  const [triggerAssign, setTriggerAssign] = useState(0);
  const [scheduleChecked, setScheduleChecked] = useState(false);
  const [rubricsJson, setRubricsJson] = useState([]);
  const [rubricsGradeDisplay, setRubricsGradeDisplay] = useState('points');
  const { assignmentType } = useParams();
  const { channelId, tenantId } = useClassroom();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const aiAssignment = useAiAssignment();
  const formConfig = useForm({
    resolver: yupResolver(assignmentFormSchema),
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    watch,
  } = formConfig;

  const watchGradeDisplay = watch('grade_display');

  const nonMarkingSchemeAttachments = currentAssignment?.attachments?.filter(
    (attachment) => attachment.metadata?.purpose !== 'marking_scheme',
  );

  const markingSchemeAttachments = currentAssignment?.attachments?.filter(
    (attachment) => attachment.metadata?.purpose === 'marking_scheme',
  );

  const handleDeletedJustUploadedAttachment = (attachment) => {
    setJustUploadedAttachments((prev) =>
      prev.filter((a) => a.id !== attachment.id),
    );
  };

  const handleJustUploadedAttachmentUpdate = (attachment) => {
    setJustUploadedAttachments((prev) =>
      prev.map((a) => (a.id === attachment.id ? attachment : a)),
    );
  };

  const handleAttachmentReset = () => {
    justUploadedAttachments.forEach((attachment) => {
      dispatch(addAssignmentAttachment({ id: assignmentId, attachment }));
    });

    setJustUploadedAttachments(() => []);
  };

  const handleAttachmentDelete = (attachment) => {
    dispatch(removeAssignmentAttachment({ id: assignmentId, attachment }));
    toast({
      title: 'Success!',
      description: 'Attachment deleted successfully',
      variant: 'success',
    });
  };

  const handleAttachmentUpdate = (attachment) => {
    dispatch(updateAssignmentAttachment({ id: assignmentId, attachment }));
  };

  const handleAttachmentUpload = (attachment) => {
    setJustUploadedAttachments((prev) => [...prev, attachment]);
  };

  const handleDeleteRubricsRow = async (index) => {
    const rubricsJsonCopy = [...rubricsJson];
    const row = rubricsJsonCopy[index - 1]; // the rubrics index starts from 1
    const objectiveId = row['objective_id'];

    if (objectiveId) {
      await DeleteObjective(channelId, objectiveId);
    }
  };

  const handleRubricsJsonChanged = (rubricsJson) => {
    setRubricsJson(() => rubricsJson);
    setValue('rubrics_json', rubricsJson);
  };

  const handleRubricsGradeDisplayChanged = (rubricsGradeDisplay) => {
    setRubricsGradeDisplay(() => rubricsGradeDisplay);
    setValue('rubrics_grade_display', rubricsGradeDisplay);
  };

  const createNewAssignment = async () => {
    try {
      setAssignmentCreateLoading(true);
      const assignment = await createAssignment(channelId, assignmentType);
      dispatch(addAssignment(assignment));
      setAssignmentId(assignment.id);
      onCreateNewAssignment(assignment.id);
      setAssignmentCreateLoading(false);
    } catch (e) {
      errorHandler(e, () => {
        setAssignmentCreateLoading(false);
        toast({
          description: 'Error occurred during assignment creation',
          variant: 'destructive',
        });
        dispatch(
          setAppError({ message: 'Error occurred during assignment creation' }),
        );
      });
    }
  };

  const onSubmit = async (data) => {
    try {
      setAssignmentUpdateLoading(true);
      const response = await updateAssignmentApi(
        channelId,
        assignmentId,
        formatPayload(data),
      );
      toast({ description: 'Assignment updated successfully' });
      dispatch(updateAssignment(response));
      setTriggerAssign(triggerAssign + 1);
    } catch (e) {
      setAssignmentUpdateLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred during assignment update',
          variant: 'destructive',
        });
      });
    }
  };

  const handleStudentAssignDone = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/${currentAssignment.id}`,
    );
  };

  const handleStudentAssignError = (error) => {
    console.log(error);
    setAssignmentUpdateLoading(false);
  };

  const handleFormError = (error) => {
    console.log(error);
    toast({
      description: 'The form is invalid',
      variant: 'destructive',
    });
  };

  const formatPayload = (data) => {
    const result = {
      ...data,
      status: 'published',
      objectives_attributes: data.objectives
        ? data.objectives.map((item) =>
            cleanObject({
              ...item,
              id: item._id || undefined,
            }),
          )
        : undefined,
    };

    return cleanObject(result);
  };

  const cleanObject = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  };

  const formatObjectivesForEdit = (objectives) => {
    return objectives.map((objective) => ({
      ...objective,
      _id: objective.id || undefined,
      name: objective.name || '',
    }));
  };

  const CustomLabel = (
    { title, icon }, // eslint-disable-line
  ) => (
    <div className='flex space-x-2 mb-2'>
      {icon && <NurtureIcon icon={icon} />} <span>{title}</span>
    </div>
  );

  useEffect(() => {
    if (context === 'AssignmentNew') createNewAssignment();
  }, [context]);

  useEffect(() => {
    if (currentAssignment || aiAssignment) {
      const oneWeekFromNow = new Date();
      oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
      const dueDate = currentAssignment?.due_date
        ? epochToDate(currentAssignment?.due_date)
        : oneWeekFromNow;

      setValue('title', aiAssignment?.title || currentAssignment?.title);
      setValue(
        'description',
        aiAssignment?.description || currentAssignment?.description,
      );

      let gradeDisplay;
      if (assignmentType) {
        gradeDisplay = `grade_display_${assignmentType}`;
      } else {
        gradeDisplay = currentAssignment?.grade_display;
      }
      setValue('grade_display', gradeDisplay);

      if (aiAssignment?.learningObjectives?.length > 0) {
        setValue(
          'objectives',
          formatObjectivesForEdit(
            aiAssignment?.learningObjectives.map((item) => ({ name: item })),
          ),
        );
      } else if (currentAssignment?.objectives?.length > 0) {
        setValue(
          'objectives',
          formatObjectivesForEdit(currentAssignment?.objectives),
        );
      } else if (gradeDisplay !== 'grade_display_rubrics') {
        setValue('objectives', [{ name: '' }]);
      }

      setValue('max_points', currentAssignment?.max_points);
      setValue('due_date', dateToEpoch(dueDate, true));

      // setValue('publish_at', dateToEpoch(dueDate, true));

      setValue(
        'marking_scheme_description',
        currentAssignment?.marking_scheme_description,
      );

      if (gradeDisplay === 'grade_display_rubrics') {
        const rubrics = aiAssignment?.rubrics ||
          currentAssignment?.rubrics_json || [
            {
              objective_name: '',
              index: 0,
              columns: [
                {
                  name: 'Poor',
                  value: '',
                  order: 1,
                  point: 1,
                },
                {
                  name: 'Good',
                  value: '',
                  order: 2,
                  point: 2,
                },
              ],
            },
          ];

        setValue('rubrics_json', rubrics);
        setRubricsJson(() => rubrics);
        setRubricsGradeDisplay(
          currentAssignment?.rubrics_grade_display || 'points',
        );
      }
    }
  }, [currentAssignment, aiAssignment]);

  return (
    <>
      {assignmentCreateLoading && <div>Loading...</div>}

      {!assignmentCreateLoading && (
        <>
          <Form className='mb-5'>
            {/*Title*/}
            <div className='mb-8'>
              <Input
                label={
                  <CustomLabel icon='purple-pen-edit' title='Task Title' />
                }
                error={errors?.title?.message}
                {...register('title')}
              />
            </div>

            {/*Description*/}
            <div className='mb-8'>
              <TextArea
                id='description'
                placeholder='Description'
                rows={10}
                label={
                  <CustomLabel
                    icon='purple-notes-question-mark'
                    title='Task Description'
                  />
                }
                error={errors?.description?.message}
                {...register('description')}
              />
              <p className='text-sm text-[#475467]'>
                Students must complete their work outside of Nurture and submit
                it as an attachment. Students cannot answer questions directly
                on the Nurture app.{' '}
              </p>
            </div>

            {/*Attachments*/}
            <div className='mb-8'>
              <FileUploader
                attachableParams={{ work_id: currentAssignment?.id }}
                onUpload={handleAttachmentUpload}
                showChildren={true}
                onReset={handleAttachmentReset}
                onDelete={handleDeletedJustUploadedAttachment}
                onUpdate={handleJustUploadedAttachmentUpdate}
              />
              <div className='mt-3'>
                <Attachments
                  showChildren={true}
                  attachments={nonMarkingSchemeAttachments}
                  onDelete={handleAttachmentDelete}
                  onUpdate={handleAttachmentUpdate}
                />
              </div>
            </div>

            {/*Learning Objectives*/}
            {watchGradeDisplay !== 'grade_display_rubrics' && (
              <div className='mb-8'>
                <CustomLabel
                  icon='purple-arrow-on-board'
                  title='Learning Outcomes'
                />
                <LearningObjectivesFields control={control} />
              </div>
            )}

            {/*Maximimum Marks*/}
            {watchGradeDisplay === 'grade_display_points' && (
              <div className='mb-8'>
                <Input
                  label={<CustomLabel title='Maximum Marks' />}
                  className='w-[200px]'
                  error={errors?.max_points?.message}
                  {...register('max_points')}
                />
              </div>
            )}

            {/*Percentage grade info*/}
            {watchGradeDisplay === 'grade_display_percentage' && (
              <div className='mb-8 flex p-4 border rounded-lg align-center'>
                <div className='flex items-center'>
                  <div className='bg-[#F4EBFF] p-2 rounded-full mr-4'>
                    <NurtureIcon icon='purple-exclamation-in-circle' />
                  </div>
                </div>

                <div className='text-sm'>
                  <div className='font-semibold text-[#344054] mb-1'>
                    All students will be graded out of 100%
                  </div>
                  <div className='font-normal text-[#475467]'>
                    Use a Marks Formative Assessment task instead to change
                    this.
                  </div>
                </div>
              </div>
            )}

            {/*Rubrics Table*/}
            {watchGradeDisplay === 'grade_display_rubrics' &&
              rubricsJson.length > 0 && (
                <div className='mb-8'>
                  <RubricsCreationTable
                    gradeDisplay={rubricsGradeDisplay}
                    rubricsJson={rubricsJson}
                    onUpdate={handleRubricsJsonChanged}
                    onUpdateGradeDisplay={handleRubricsGradeDisplayChanged}
                    onDeleteRow={handleDeleteRubricsRow}
                  />
                </div>
              )}

            {/*Marking scheme*/}
            <div className='mb-8'>
              <div className='flex items-center mb-4'>
                <NurtureIcon icon='purple-dot-lines' className='mr-2' />
                <div>Marking Scheme (Optional)</div>
              </div>
              <div className=''>
                <div className='mb-4'>
                  <TextArea
                    rows={6}
                    {...register('marking_scheme_description')}
                  />
                </div>
                <FileUploader
                  buttonView={true}
                  buttonViewClassName='bg-[#FFFFFF] hover:bg-[#FFFFFF] border hover:border border-[#D0D5DD] hover:border-[#D0D5DD] font-semibold text-sm text-[#344054] hover:text-[#344054] rounded-lg'
                  buttonViewContent={
                    <div className='flex items-center'>
                      Upload Attachments{' '}
                      <NurtureIcon className='ml-2' icon='black-plus' />
                    </div>
                  }
                  attachableParams={{
                    work_id: currentAssignment?.id,
                    metadata: { purpose: 'marking_scheme' },
                  }}
                  onUpload={handleAttachmentUpload}
                  onReset={handleAttachmentReset}
                  onDelete={handleDeletedJustUploadedAttachment}
                  onUpdate={handleJustUploadedAttachmentUpdate}
                />
                <div className='mt-3'>
                  <Attachments
                    attachments={markingSchemeAttachments}
                    onDelete={handleAttachmentDelete}
                    onUpdate={handleAttachmentUpdate}
                  />
                </div>
              </div>
            </div>

            <Separator className='my-12' />

            {/*Assign To students*/}
            <div className='md:flex md:justify-between md:items-center mb-8'>
              <div className='flex items-center'>
                <NurtureIcon icon='purple-user-plus' className='mr-2' />
                <div>Assign to</div>
              </div>
              <div className='w-full md:w-[300px]'>
                <StudentsAssignField
                  triggerAssign={triggerAssign}
                  currentAssignment={currentAssignment}
                  onAssign={handleStudentAssignDone}
                  onError={handleStudentAssignError}
                />
              </div>
            </div>

            {/*Due Date*/}
            <div className='md:flex md:justify-between md:items-center mb-8'>
              <div className='flex items-center'>
                <NurtureIcon icon='purple-calendar' className='mr-2' />
                <div>Due Date</div>
              </div>
              <div className='w-full md:w-[300px]'>
                <div className='mb-3'>
                  <div>
                    <Controller
                      name='due_date'
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <DateInput
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*Schedule at*/}
            <div className='mb-8'>
              <div className='md:flex md:justify-between md:items-center mb-4'>
                <div className='flex items-center md:flex-grow'>
                  <NurtureIcon icon='purple-calendar-filled' className='mr-2' />
                  <div>Schedule this assessment</div>
                </div>
                <div className='w-full md:w-[100px] md:flex md:justify-end'>
                  <Switch
                    name='schedule_assessment'
                    checked={scheduleChecked}
                    onCheckedChange={(value) => setScheduleChecked(value)}
                  />
                </div>
              </div>

              {/*Publish at*/}
              {scheduleChecked && (
                <div className='md:flex md:justify-between md:items-center mb-4'>
                  <div className='flex items-center'>
                    <NurtureIcon icon='purple-calendar' className='mr-2' />
                    <div>Publish at</div>
                  </div>
                  <div className='w-full md:w-[300px]'>
                    <div className='mb-3'>
                      <div>
                        <Controller
                          name='publish_at'
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => {
                            return (
                              <DateInput
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>

          <Button
            className='w-full bg-[#7F56D9] text-white hover:bg-[#7F56D9] mt-8'
            withLoader={true}
            loading={assignmentUpdateLoading}
            loaderText='Loading...'
            onClick={handleSubmit(onSubmit, handleFormError)}
          >
            Publish Task to All Students
          </Button>
        </>
      )}
    </>
  );
};

AssignmentForm.defaultProps = {
  context: 'AssignmentNew',
  defaultAssignmentId: null,
  onCreateNewAssignment: () => {},
};

AssignmentForm.propTypes = {
  context: PropTypes.oneOf(['AssignmentNew']),
  defaultAssignmentId: PropTypes.string,
  onCreateNewAssignment: PropTypes.func,
};

export default AssignmentForm;
