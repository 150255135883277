import { createSlice } from '@reduxjs/toolkit';

export const classroomSlice = createSlice({
  name: 'classroom',
  initialState: {
    channelId: null,
    tenantId: null,
    members: [],
  },
  reducers: {
    setClassroomDetails: (state, action) => {
      state.channelId = action.payload.channelId;
      state.tenantId = action.payload.tenantId;
      return state;
    },
    setClassroomMembers: (state, action) => {
      state.members = action.payload;
      return state;
    },
  },
});

export const { setClassroomDetails, setClassroomMembers } =
  classroomSlice.actions;

export default classroomSlice.reducer;
