import StorageService from 'services/StorageService';
import { Avatar } from '@gonurture/design-system';
import UserAssignmentList from './UserAssignmentList';

const Home = () => {
  const user = StorageService.getObject('user');

  return (
    <div className='py-8'>
      <div className='mb-4'>
        <h1>User</h1>
        <p>
          <strong>Name:</strong> {user.display_name}
        </p>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <div>Avatar</div>
          <Avatar src={user.avatar_url} width='100' height='100' />
        </p>
      </div>

      <div className='mt-5'>
        <UserAssignmentList />
      </div>
    </div>
  );
};

export default Home;
