import StorageService from 'services/StorageService';
import AssignmentList from '../AssignmentList';
import TeacherHomeNav, { NAVS } from './TeacherHomeNav';
import { useState } from 'react';

const Home = () => {
  const [activeTab, setActiveTab] = useState(NAVS.tasks);

  const user = StorageService.getObject('user');

  const greeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  return (
    <div className='py-8'>
      <div className='mb-8 text-2xl mt-10 md:mt-0'>
        &#128075; {greeting()} {user.display_name.split()[0]}
      </div>

      <TeacherHomeNav activeTab={activeTab} onTabChange={setActiveTab} />

      {activeTab === NAVS.tasks && (
        <div className='mt-10'>
          <AssignmentList />
        </div>
      )}

      {activeTab === NAVS.dashboard && (
        <div className='mt-10'>
          <div>Dashboard</div>
        </div>
      )}
    </div>
  );
};

export default Home;
