import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const FeedbackDetailsHeader = ({ activeState, onPrev, activeStateConfig }) => {
  return (
    <div className='flex justify-between items-center'>
      {activeState !== 'learningOutcomes' && (
        <Button outline className='mr-2' onClick={onPrev}>
          <NurtureIcon icon='black-arrow-left' />
        </Button>
      )}

      {activeState !== 'summary' && (
        <div className='flex justify-end items-center flex-grow'>
          <NurtureIcon icon={activeStateConfig.icon} />
          <div className='ml-2 font-semibold font-normal'>
            {activeStateConfig.text}
          </div>
        </div>
      )}
    </div>
  );
};

FeedbackDetailsHeader.propTypes = {
  activeState: PropTypes.string.isRequired,
  onPrev: PropTypes.func.isRequired,
  activeStateConfig: PropTypes.object.isRequired,
};

export default FeedbackDetailsHeader;
