import { MultiSelect, Checkbox } from '@gonurture/design-system';
import { components } from 'react-select';
import PropTypes from 'prop-types';
const AssignmentQuestionTypeField = ({ values, options, error, onChange }) => {
  const valueToUse =
    values?.map((v) => options.find((o) => o.value === v) || {}) || [];

  /* eslint-disable */
  const Option = (props) => {
    return (
      <components.Option className='!bg-transparent !text-black' {...props}>
        <Checkbox
          className='data-[state=checked]:bg-[#7F56D9] border-[#D0D5DD]'
          checked={props.isSelected}
          label={props.label}
          labelClassName='cursor-pointer'
          onChange={() => null} // Prevent checkbox from firing onChange event
        />
      </components.Option>
    );
  };
  /* eslint-enable */

  const customClassNames = {
    multiValue: () => 'rounded bg-white border',
    multiValueLabel: () => 'font-medium bg-white',
    multiValueRemove: () => 'hover:bg-gray-200 text-gray-500 rounded bg-white',
    // option: () => '!bg-transparent !text-black !cursor-pointer',
  };

  const handleChange = (values) => {
    onChange(values.map((v) => v.value));
  };

  return (
    <MultiSelect
      components={{ Option }}
      value={valueToUse}
      options={options}
      error={error}
      onValuesChange={handleChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      classNames={customClassNames}
    />
  );
};

AssignmentQuestionTypeField.defaultProps = {
  values: null,
  options: [],
  error: null,
  onChange: () => {},
};

AssignmentQuestionTypeField.propTypes = {
  values: PropTypes.array,
  options: PropTypes.array,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default AssignmentQuestionTypeField;
