import { BaseFile } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const Attachment = ({ attachment, canDelete, children, ...props }) => {
  return (
    <BaseFile
      filename={attachment.filename}
      filesize={attachment.filesize}
      filetype={attachment.filetype}
      canDelete={canDelete}
      {...props}
    >
      {children}
    </BaseFile>
  );
};

Attachment.defaultProps = {
  canDelete: true,
  children: null,
};

Attachment.propTypes = {
  attachment: PropTypes.shape({
    filename: PropTypes.string,
    filesize: PropTypes.string,
    filetype: PropTypes.string,
  }).isRequired,
  canDelete: PropTypes.bool,
  children: PropTypes.node,
};

export default Attachment;
