import { createSlice } from '@reduxjs/toolkit';

export const appErrorSlice = createSlice({
  name: 'appError',
  initialState: null,
  reducers: {
    setAppError: (state, action) => {
      state = action.payload;
      return state;
    },
    removeAppError: (state) => {
      state = null;
      return state;
    },
  },
});

export const { setAppError, removeAppError } = appErrorSlice.actions;

export default appErrorSlice.reducer;
