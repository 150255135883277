import { object, string, number, mixed, array, boolean } from 'yup';

const assignmentFormSchema = object({
  title: string().required('Title is required').nullable(),
  description: string().required('Description is required').nullable(),
  due_date: number(),
  publish_at: number(),
  grade_display: string().required('Grade display is required').nullable(),
  marking_scheme_description: string().nullable(),
  objectives: array().when('grade_display', ([grade_display], schema) => {
    if (grade_display !== 'grade_display_rubrics') {
      return schema.min(1, 'At least one objective is required').of(
        object({
          _id: number(),
          _destroy: boolean(),
          id: string().nullable(),
          name: string().required('Objective Name is required'),
        }),
      );
    }
  }),
  max_points: number()
    .nullable()
    .when('grade_display', ([grade_display], schema) => {
      if (grade_display === 'grade_display_points') {
        return schema
          .required('Max points is required')
          .transform((value) => (isNaN(value) ? undefined : value))
          .min(1, 'The minimum allowable mark is 1')
          .nullable();
      }
    }),
  rubrics_json: mixed().when('grade_display', ([grade_display], schema) => {
    if (grade_display === 'grade_display_rubrics') {
      return schema.required('Rubrics is required');
    }
  }),
  rubric_columns_attributes: mixed(),
});

export default assignmentFormSchema;
