import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAssignments, useClassroom } from 'store/selectors';
import { Card, useToast } from '@gonurture/design-system';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { setAppError } from 'store/reducers/app-error-reducer';
import { addUserAssignments } from 'store/reducers/user-assignments-reducer';
import { userAssignmentsList } from 'apis/';

const UserAssignmentList = () => {
  const [userAssignmentsLoading, setUserAssignmentsLoading] = useState(false);
  const navigate = useNavigate();
  const { channelId, tenantId } = useClassroom();
  const userAssignments = useUserAssignments();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const fetchUserAssignments = async () => {
    try {
      setUserAssignmentsLoading(true);
      const { user_works: userAssignments } =
        await userAssignmentsList(channelId);
      dispatch(addUserAssignments(userAssignments));
      setUserAssignmentsLoading(false);
    } catch (e) {
      setUserAssignmentsLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignments',
          variant: 'error',
        });
        dispatch(
          setAppError({ message: 'Error occurred when fetching assignments' }),
        );
      });
    }
  };

  useEffect(() => {
    fetchUserAssignments();
  }, []);

  return (
    <>
      {userAssignmentsLoading && <div>Loading...</div>}

      {!userAssignmentsLoading && (
        <div>
          <h1 className='mb-5'>Assignment List</h1>
          <div className='flex flex-wrap -mx-3'>
            {userAssignments.map((userAssignment) => (
              <div
                key={userAssignment.id}
                className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-3 mb-6'
              >
                <Card
                  className='h-full cursor-pointer hover:bg-gray-100'
                  onClick={() =>
                    navigate(
                      `/teams/${tenantId}/channels/${channelId}/user-assignment/${userAssignment.id}`,
                    )
                  }
                >
                  {userAssignment.work.title}
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UserAssignmentList;
