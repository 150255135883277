import { NurtureIcon } from '@gonurture/design-system';
import { ASSIGNMENT_TYPES } from 'pages/assignment/assignment-type/AssignmentType';
import PropTypes from 'prop-types';

const AssignmentTypeHeader = ({ assignmentType }) => {
  return (
    <div className='flex space-x-2'>
      <NurtureIcon icon={ASSIGNMENT_TYPES[assignmentType].icon} />
      <div>{ASSIGNMENT_TYPES[assignmentType].title} Task</div>
    </div>
  );
};

AssignmentTypeHeader.propTypes = {
  assignmentType: PropTypes.oneOf(Object.keys(ASSIGNMENT_TYPES)).isRequired,
};

export default AssignmentTypeHeader;
