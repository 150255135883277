import { createRequest } from 'services/ApiService';

export const userAssignmentShow = async (channelId, userAssignmentId) => {
  try {
    return await createRequest({
      url: `user_works/${userAssignmentId}`,
      method: 'get',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const userAssignmentsList = async (channelId) => {
  try {
    return await createRequest({
      url: `user_works`,
      method: 'get',
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const bulkAssign = (channelId, payload) => {
  try {
    return createRequest({
      url: 'user_works/bulk_assign',
      method: 'put',
      payload,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const bulkUnAssign = (channelId, payload) => {
  try {
    return createRequest({
      url: 'user_works/bulk_unassign',
      method: 'put',
      payload,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
