// src/app/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './user-reducer';
import classroomReducer from './classroom-reducer';
import appErrorReducer from './app-error-reducer';
import assignmentsReducer from './assignments-reducer';
import userAssignmentsReducer from './user-assignments-reducer';
import aiAssignmentReducer from './ai-assignment-reducer';

const rootReducer = combineReducers({
  user: userReducer,
  classroom: classroomReducer,
  appError: appErrorReducer,
  assignments: assignmentsReducer,
  userAssignments: userAssignmentsReducer,
  aiAssignment: aiAssignmentReducer,
});

export default rootReducer;
