import { createRequest } from 'services/ApiService';

export const getUserProfile = async (channelId) => {
  try {
    return await createRequest({
      url: `teams/classrooms/${channelId}/user_profile`,
      method: 'get',
    });
  } catch (error) {
    await Promise.reject(error);
  }
};
