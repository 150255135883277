import TeamsAuthenticate from './TeamsAuthenticate';
import BrowserAuthenticate from './BrowserAuthenticate';

const Authenticate = () => {
  const inTeams = () => {
    return !!(
      (window.parent === window.self && window.nativeInterface) ||
      window.navigator.userAgent.includes('Teams/') ||
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    );
  };

  return <>{inTeams() ? <TeamsAuthenticate /> : <BrowserAuthenticate />}</>;
};

export default Authenticate;
